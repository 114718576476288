<template>
  <div class="com-container">
    <!-- 种植记录轮播组件 -->
    <div class="com-chart" style="padding-top:8px" ref='plant_box'>
      <dv-scroll-board ref="plant_ref" :config="config" style="width:100%;height:250px" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EchartsAdminPlantlist',

  data() {
    return {
      config: {}
    }
  },

  mounted() {
    this.getPlantData()
  },

  methods: {
    //获取种植列表数据
    async getPlantData() {
      const res = await this.$api.getPlantList()
      // 增加字体颜色
      res.data = res.data.map((item, index) => {
        if (index < 5) {
          return [`<span style="color:#FE211E;">${item[0]}</span>`, item[1], item[2], item[3]]
        } else if (index >= 5 && index < 10) {
          return [`<span style="color:#16F2D9;">${item[0]}</span>`, item[1], item[2], item[3]]
        } else {
          return [`<span style="color:#0BA82C;">${item[0]}</span>`, item[1], item[2], item[3]]
        }
      })
      this.config = res
      this.config.headerBGC = '#2E72BF' //设置表头颜色
      this.config.oddRowBGC = '#052666' //设置奇数行颜色
      this.config.evenRowBGC = '' //设置偶数行颜色
      this.config.columnWidth = [60]
    }
  }
}
</script>

<style lang="less" scoped>
.com-container {
  position: absolute;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 87%;
  overflow: hidden;

  .com-chart {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>