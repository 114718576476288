<template>
  <div class="com-container">
    <div class="com-chart">
      <div class="item-left">
        <div class="item-title">
          <span :style="{color:activeColor,fontSize:actionFontSize}">●</span>
          {{title}}
        </div>
        <div class="item-info">
          <countTo prefix='￥' :startVal='startVal1' :endVal='endVal1' :duration='3000' :decimals='decimals' style="fontSize:0.28rem"></countTo>
          <span>万元</span>
        </div>
      </div>
      <div class="item-right">
        <div class="item-image">
          <img :src="imgSrc" :alt="imgSrc">
        </div>
        <div class='right-info'>
          <countTo suffix='%' :startVal='startVal2' :endVal='endVal2' :duration='duration' :decimals='decimals' style="fontSize:0.2rem"></countTo>
          <span :style="{color:activeColor,fontSize:actionFontSize}">↑</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to'
export default {
  name: 'Curve',
  components: { countTo },
  props: ['title', 'endVal1', 'endVal2', 'activeColor', 'imgSrc', 'decimals', 'startVal1', 'startVal2','duration'],
  data() {
    return {
      actionFontSize: '0.3rem', //图标字体大小
      timerId: null //定时器ID
    }
  },

  mounted() {},

  methods: {
    //数据切换定时器
    startInterval() {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        this.endVal1 = endVal1 + 15
        this.endVal2 = endVal2 + 15
        console.log(this.endVal1, this.endVal1)
      }, 5000)
    }
  }
}
</script>

<style lang="less" scoped>
.com-container {
  position: absolute;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 87%;
  overflow: hidden;

  .com-chart {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .item-left {
      height: 100%;
      width: 61%;
      float: left;
      color: #fff;
      font-size: 0.2rem;
      padding-left: 0.125rem;
      // border: 1px solid red;
      .item-title {
        height: 50%;
        line-height: 0.75rem;
      }
      .item-info {
        font-size: 0.3rem;
        height: 50%;
        line-height: 0.75rem;
        span {
          font-size: 0.15rem;
        }
      }
    }
    .item-right {
      float: right;
      height: 100%;
      width: 32.6%;
      // border: 1px solid red;
      .item-image {
        padding-top: 0.1875rem;
        width: 1.125rem;
        img {
          width: 80%;
          height: 80%;
        }
      }
      .right-info {
        width: 100%;
        height: 1rem;
        color: #fff;
        line-height: 1.188rem;
        font-size: 0.2rem;
      }
    }
  }
}
</style>