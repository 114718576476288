<template>
  <div class="not-found-container">
    <span class="info-text">哎呀，农场太大，迷路了！</span>
    <button class="go-home" @click="goHome"><i class="el-icon-s-home"></i>返回首页</button>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',

  data() {
    return {}
  },

  mounted() {},

  methods: {
    goHome() {
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="less" scoped>
.not-found-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url('../assets/404.png') no-repeat top center;
  background-position-y: -120px;
  background-size: 100%;
  overflow: hidden;
  .info-text {
    position: absolute;
    left: 50%;
    top: 78%;
    transform: translate(-50%, -78%);
    display: block;
    font-size: 24px;
  }
  .go-home {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -90%);
    top: 90%;
    width: 150px;
    height: 50px;
    font-size: 18px;
    border-radius: 25px;
    letter-spacing: 2px;
    color: #ffffff;
    background-color: #63d027;
    border: 1px solid #63d027;
  }
  .go-home:hover {
    cursor: pointer;
  }
}
</style>