<template>

  <dv-full-screen-container class="container">
    <div class="header-box">
      <Header @fatherMethod="exitFullscreen"></Header>
    </div>

    <!-- 内容主体 -->
    <div class="main-box1">
      <!-- 左侧盒子 -->
      <div class="left-box">
        <!-- 左上盒子 -->
        <div class="left-top">
          <dv-border-box-6 class="item1" :color="['#4cbaff']">
            <Per></Per>
          </dv-border-box-6>
        </div>
        <!-- 左下盒子 -->
        <div class="left-bottom">
          <dv-border-box-6 class="item2" :color="['#4cbaff']">
            <Hot></Hot>
          </dv-border-box-6>
        </div>
      </div>
      <!-- 右侧盒子 -->
      <div class="right-box">
        <!-- 右上盒子 -->
        <div class="right-top">
          <div class="right-item-top">
            <dv-border-box-6 v-for="(item,index) in marketData" class="item3" :color="['#4cbaff']" :key="index">
              <Curve :title='item.title' :startVal1='item.startVal1' :startVal2='item.startVal2' :endVal1='item.endVal1' :endVal2='item.endVal2' :duration='item.duration' :activeColor='item.activeColor' :imgSrc='item.imgSrc' :decimals='item.decimals'></Curve>
            </dv-border-box-6>
          </div>
          <div class="right-item-bottom">
            <dv-border-box-6 class="item7" :color="['#4cbaff']">
              <Rank></Rank>
            </dv-border-box-6>
          </div>
        </div>
        <!-- 右下盒子 -->
        <div class="right-bottom">
          <dv-border-box-6 class="item8" :color="['#4cbaff']">
            <div class="right-bottom-info">
              <div class="title">▎销售金额分析</div>
              <div class="info">
                <div class="info-item">累计利润<countTo :startVal='startVal1' :endVal='endVal1' :duration='3000' :decimals='decimals' style="fontSize:0.3rem; margin-left:0.125rem;margin-right:0.125rem;color:#16F2D9"></countTo>万元</div>
                <div class="info-item">累计利润<countTo :startVal='startVal2' :endVal='endVal2' :duration='3000' :decimals='decimals' style="fontSize:0.3rem; margin-left:0.125rem;margin-right:0.125rem;color:#16F2D9"></countTo>万元</div>
                <div class="info-item">累计利润<countTo :startVal='startVal3' :endVal='endVal3' :duration='3000' :decimals='decimals' style="fontSize:0.3rem; margin-left:0.125rem;margin-right:0.125rem;color:#16F2D9"></countTo>万元</div>
              </div>
            </div>
            <img :src="require('../assets/img/sale-page-images/fg.png')" alt="" class="right-img">
            <div class="right-bottom-chart">
              <Analyze></Analyze>
            </div>
          </dv-border-box-6>
        </div>
      </div>
    </div>
  </dv-full-screen-container>
</template>

<script>
import Header from '@/components/HomePageComponents/Header.vue'
import Per from '@/components/SalePageComponents/PercentageComplete.vue'
import Hot from '@/components/SalePageComponents/Hot.vue'
import Curve from '@/components/SalePageComponents/Curve.vue'
import Rank from '@/components/SalePageComponents/RankingList.vue'
import Analyze from '@/components/SalePageComponents/Analyze.vue'
import countTo from 'vue-count-to'
export default {
  name: 'HomePage',
  components: { Header, Per, Hot, Curve, Rank, Analyze, countTo },
  data() {
    return {
      // 全屏状态数据
      fullScreenStatus: {},
      //曲线统计模块数据
      marketData: [
        {
          title: '总销售金额统计',
          startVal1: 0,
          startVal2: 0,
          endVal1: 2893.0,
          endVal2: 20.49,
          duration: 3000,
          activeColor: '#00FFFF',
          decimals: 2,
          imgSrc: require('../assets/img/sale-page-images/curve1.png')
        },
        {
          title: '本月销售金额统计',
          startVal1: 0,
          startVal2: 0,
          endVal1: 1394.0,
          endVal2: 45.49,
          duration: 3000,
          activeColor: '#FF75E2',
          decimals: 2,
          imgSrc: require('../assets/img/sale-page-images/curve2.png')
        },
        {
          title: '同比销售金额统计',
          startVal1: 0,
          startVal2: 0,
          endVal1: 5843.0,
          endVal2: 90.49,
          duration: 3000,
          activeColor: '#7E2DFF',
          decimals: 2,
          imgSrc: require('../assets/img/sale-page-images/curve3.png')
        },
        {
          title: '年销售金额统计',
          startVal1: 0,
          startVal2: 0,
          endVal1: 5353.0,
          endVal2: 70.39,
          duration: 3000,
          activeColor: '#FFB33B',
          decimals: 2,
          imgSrc: require('../assets/img/sale-page-images/curve4.png')
        }
      ],
      startVal1: 0,
      startVal2: 0,
      startVal3: 0,
      endVal1: 3423.84,
      endVal2: 2423.84,
      endVal3: 1423.84,
      decimals: 2,
      timerId: null
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.startInterval()
    })
  },

  methods: {
    //数据切换定时器
    startInterval() {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        const marketDataUpdate = this.marketData.map(item => {
          return {
            title: item.title,
            startVal1: item.endVal1,
            startVal2: item.endVal2,
            activeColor: item.activeColor,
            decimals: 2,
            duration: 3000,
            imgSrc: item.imgSrc,
            endVal1: item.endVal1 + 10,
            endVal2: item.endVal2 + 0.01
          }
        })
        this.marketData = marketDataUpdate
        this.startVal1 = this.endVal1
        this.startVal2 = this.endVal2
        this.startVal3 = this.endVal3
        this.endVal1 += 10
        this.endVal2 += 10
        this.endVal3 += 10
      }, 5000)
    },
    // 退出全屏
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
    }
  }
}
</script>

<style lang="less" scoped>
// 全屏样式的定义 使用最高权重
.fullscreen {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  z-index: 100;
  background-image: url('../assets/img/home-page-images/bg1.png');
  background-repeat: no-repeat;
  background-size: 100%;
}
body {
  margin: 0;
  .container {
    width: 100%;
    height: 100%;
    background: url('../assets/img/home-page-images/bg1.png');
    background-size: 100%;
    .header-box {
      width: 100%;
      height: 8%;
    }
    .main-box1 {
      display: flex;
      width: 100%;
      height: 90%;
      justify-content: center;
      .left-box {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .left-top,
        .left-bottom {
          flex: 1;
          width: 100%;
          padding: 10px;
          .item1,
          .item2 {
            width: 97%;
            height: 100%;
            // margin: 10px;
          }
        }
      }
      .right-box {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .right-top,
        .right-bottom {
          padding: 10px;
          flex: 1;
          width: 100%;
          .item8 {
            width: 98%;
            height: 100%;
            .right-bottom-info {
              position: relative;
              float: left;
              width: 24%;
              height: 100%;
              // border: 1px solid red;
              .title {
                position: absolute;
                top: 0.5rem;
                left: 0.625rem;
                color: #fff;
                font-size: 0.26rem;
                font-weight: bold;
              }
              .info {
                position: absolute;
                top: 85px;
                left: 0;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                align-content: stretch;
                // border: 1px solid red;
                width: 100%;
                height: 75%;
              }
              .info-item {
                width: 100%;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.175rem;
                // border: 1px solid #fff;
              }
            }
            .right-img {
              position: relative;
              top: 55%;
              transform: translateY(-50%);
              height: 65%;
              width: 1px;
            }
            .right-bottom-chart {
              float: right;
              width: 74%;
              height: 100%;
              // border: 1px solid red;
            }
          }
        }
        .right-top {
          display: flex;
          flex-direction: column;
          .right-item-top {
            padding: 0 0 20px 0;
            height: 30%;
            width: 97%;
            display: flex;
            .item3 {
              flex: 1;
              height: 100%;
              margin-right: 20px;
            }
            .item3:nth-last-of-type(1) {
              margin: 0;
            }
          }
          .right-item-bottom {
            height: 64%;
            width: 97%;
            .item7 {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>