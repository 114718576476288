<template>
  <!-- 通过for渲染数据，{光照，值,src} -->
  <div class="weathertotal-container">
    <div class="weather-item">
      <img src="@/assets/img/home-page-images/weater-total/beam.png" alt="">
      光照:<span>{{weatherData[0].value}}%</span>
    </div>
    <div class="weather-item">
      <img src="@/assets/img/home-page-images/weater-total/rainfall.png" alt="">
      雨量:<span>{{weatherData[1].value}}mm</span>
    </div>
    <div class="weather-item">
      <img src="@/assets/img/home-page-images/weater-total/temperature.png" alt="">
      温度:<span>{{weatherData[2].value}}℃</span>
    </div>
    <div class="weather-item">
      <img src="@/assets/img/home-page-images/weater-total/soil-moisture.png" alt="">
      土壤温度:<span>{{weatherData[3].value}}℃</span>
    </div>
    <div class="weather-item">
      <img src="@/assets/img/home-page-images/weater-total/sd.png" alt="">
      湿度:<span>{{weatherData[4].value}}%</span>
    </div>
    <div class="weather-item">
      <img src="@/assets/img/home-page-images/weater-total/humidity.png" alt="">
      土壤湿度:<span>{{weatherData[5].value}}%</span>
    </div>
    <div class="weather-item">
      <img src="@/assets/img/home-page-images/weater-total/wind-speed.png" alt="">
      风速:<span>{{weatherData[6].value}}m/s</span>
    </div>
    <div class="weather-item">
      <img src="@/assets/img/home-page-images/weater-total/wind-direction.png" alt="">
      风向:<span>{{weatherData[7].value}}°</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WeatherTotal',

  data() {
    return {
      weatherData: [
        {
          name: 'light',
          value: 60
        },
        {
          name: 'rainfall',
          value: 25.5
        },
        {
          name: 'temperature',
          value: 16.8
        },
        {
          name: 'soilTemperature',
          value: 25.7
        },
        {
          name: 'humidity',
          value: 90.2
        },
        {
          name: 'soilHumidity',
          value: 17.9
        },
        {
          name: 'windSpeed',
          value: 2.67
        },
        {
          name: 'windDirection',
          value: 244.02
        }
      ]
    }
  },
  created() {},
  mounted() {},

  methods: {}
}
</script>

<style lang="less" scoped>
.weathertotal-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  color: #ffffff;
  .weather-item {
    margin: 0 0.1875rem 0 0.1rem;
    width: 2.425rem;
    height: 0.5rem;
    line-height: 0.5rem;
    font-size: 0.225rem;
    img {
      width: 0.5rem;
      height: 0.5rem;
      vertical-align: middle;
    }
    span {
      margin: 0.0625rem;
      color: #01c4f7;
      font-size: 0.25rem;
    }
  }
  .weather-item:nth-of-type(1),
  .weather-item:nth-of-type(2) {
    margin-top: 25px;
  }
}
</style>