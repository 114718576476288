// 权限设置 dialog 对话框
<template>
  <div class="add-role-from">
    <el-dialog :visible.sync="dialogVisibleSetRole" title="权限设置" :before-close="handleClose">
      <el-form ref="addSetRoleFrom" :model="setRoleFrom" label-width="100px">
        <el-form-item label="角色名称:">
          {{setRoleFrom.roleName}}
        </el-form-item>
        <el-form-item label="选择权限">
          <el-tree ref="tree" :data="menuList" :default-checked-keys="checkedKeys" show-checkbox node-key="_id" default-expand-all :props="{label:'menuName'}">
          </el-tree>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel('addsetRoleFrom')">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'Addrole',
  props: ['setRoleFrom', 'menuList'],
  data() {
    return {
      dialogVisibleSetRole: false,
      checkedKeys: [],
      setRoleId: ''
    }
  },

  mounted() {},

  methods: {
    //关闭弹窗提示
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
          this.$refs.addsetRoleFrom.resetFields() //重置当前表单,避免选择按钮对后续的影响
          this.dialogVisibleSetRole = false
        })
        .catch(_ => {})
    },
    //表单控件关闭弹窗提示 -->取消按钮询问事件
    handleCancel(formName) {
      // console.log(this.action)
      //需要对 action 的状态进行判断 如果为 edit 则不清空表单
      this.$confirm('确认取消？')
        .then(_ => {
          // this.$refs[formName].resetFields() //重置当前表单
          this.dialogVisibleSetRole = false
        })
        .catch(_ => {})
    },
    //点击确定按钮提交，关闭弹窗
    async handleSubmit() {
      let nodes = this.$refs.tree.getCheckedNodes()
      let halfKeys = this.$refs.tree.getHalfCheckedKeys()
      let checkedKeys = [] //每一个选中的按钮
      let parentKeys = [] //每一个选中的菜单
      nodes.map(node => {
        if (!node.children) {
          checkedKeys.push(node._id)
        } else {
          parentKeys.push(node._id)
        }
      })
      let params = {
        _id: this.setRoleFrom._id,
        permissionList: {
          checkedKeys,
          halfCheckedKeys: parentKeys.concat(halfKeys)
        }
      }
      await this.$api.updatePermission(params)
      this.dialogVisibleSetRole = false
      this.$message.success('权限设置成功！(^～^)')
      this.$parent.getRoleList()
    }
  },
  watch: {
    //使用深度监听，让数据进行响应式变化
    '$store.state.dialogVisibleSetRole': {
      immediate: true,
      deep: true,
      handler: function (newVal, oldVal) {
        this.dialogVisibleSetRole = newVal
      }
    },
    //使用数据监听，监听dialogVisibleSetRole数据变化，变化提交到vuex中
    dialogVisibleSetRole: {
      handler: function (newVal, oldVal) {
        this.$store.commit('changeDialogSetRole', this.dialogVisibleSetRole)
      }
    },
    //对父组件传递过来的 roleFrom 进行数据监听
    setRoleFrom: {
      handler: function (newVal, oldVal) {
        this.setRoleFrom = newVal
        let { checkedKeys } = this.setRoleFrom.permissionList
        this.checkedKeys = checkedKeys
        // console.log('this.checkedKeys=>', this.checkedKeys)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.add-role-from {
  width: 100%;
  height: 100%;
}
</style>