<template>
  <div class="com-container">
    <!-- 风速组件 -- 折线图 -->
    <div class="com-chart" ref="wind_ref">

    </div>
  </div>
</template>

<script>
export default {
  name: 'WindSpeed',

  data() {
    return {
      chartInstane: null,
      allData: null //从服务器获取数据
    }
  },

  mounted: function () {
    //初始化图表
    this.initChart()
    this.getData()
    //监听页面变化
    window.addEventListener('resize', this.screenAdapter)
    //第一次加载，主动调用
    this.screenAdapter()
  },
  destroyed() {
    //销毁监听事件
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    //初始化 echart 对象
    initChart: function () {
      this.chartInstane = this.$echarts.init(this.$refs.wind_ref, 'chalk')
      //配置项
      const initOption = {
        //配置坐标轴
        grid: {
          left: '3%',
          top: '20%',
          right: '3%',
          bottom: '1%',
          containLabel: true //x轴文字显示
        },
        tooltip: {
          //提示工具
          trigger: 'axis'
        },
        legend: {
          show: false,
          // left: 20,
          top: '1%',
          icon: 'circle'
          // itemStyle: {
          //   color: '#fff'
          // }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false //与y轴间隙
        },
        yAxis: [
          {
            type: 'value',
            name: '风速(m/s)',
            min: 0,
            axisLabel: {
              showMinLabel: false,
              interval: 0,
              color: 'rgba(207, 227, 252, 1)',
              formatter: '{value}'
            },
            nameTextStyle: {
              color: '#FFFFFF'
              // align: 'left'
            }
          }
        ]
      }
      //设置
      this.chartInstane.setOption(initOption)
    },
    //获取数据
    async getData() {
      const res = await this.$api.getWindSpeed()
      this.allData = res
      //更新数据
      this.updateChart()
    },
    //更新处理数据
    updateChart() {
      //半透明颜色值
      const colorArr1 = ['rgba(255,80,124,0)']
      const colorArr2 = ['rgba(255,80,124,0.5)']
      //x轴类目轴数据
      const timeArr = this.allData.common.month
      //y轴的数据 series 下的数据
      const temperatureArr = this.allData.WindSpeed.data
      const temperature = temperatureArr.map((item, index) => {
        return {
          name: item.name,
          type: 'line',
          data: item.data,
          stack: 'temperature', //设置堆叠图
          areaStyle: {
            //堆叠面积显示
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                //0%时颜色值
                offset: 0,
                color: colorArr1[index]
              },
              {
                //100%时的颜色值
                offset: 1,
                color: colorArr2[index]
              }
            ])
          },
          lineStyle: {
            color: 'rgba(255,80,124,1)'
          }
        }
      })
      const seriesArr = [...temperature]
      //获取图例数据
      const legendArr1 = temperatureArr.map(item => {
        return item.name
      })
      // 数据配置
      const dataOption = {
        xAxis: {
          data: timeArr
        },
        legend: {
          data: [...legendArr1]
        },

        series: seriesArr
      }
      this.chartInstane.setOption(dataOption)
    },
    //图表响应式变化
    screenAdapter() {
      //计算文字大小
      const fontSize = (this.$refs.wind_ref.offsetWidth / 100) * 3.6
      const adapterOption = {
        legend: {
          itemWidth: fontSize,
          itemHeight: fontSize,
          itemGap: fontSize,
          textStyle: {
            fontSize: fontSize / 1.4
          }
        },
        yAxis: [
          {
            nameTextStyle: {
              fontSize: fontSize > 48 ? 24 : fontSize / 1.2
            }
          }
        ]
      }
      this.chartInstane.setOption(adapterOption)
      this.chartInstane.resize()
    }
  }
}
</script>

<style lang="less" scoped>
.com-container {
  position: absolute;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 87%;
  overflow: hidden;

  .com-chart {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>