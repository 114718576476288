<template>
  <div class="container use-container">
    <div class="query-from">
      <el-form :inline="true" :model="user" class="demo-form-inline" ref="form">
        <el-form-item label="用户ID" prop="userId">
          <el-input v-model="user.userId" placeholder="请输入用户ID"></el-input>
        </el-form-item>
        <el-form-item label="用户名称" prop="userName">
          <el-input v-model="user.userName" placeholder="请输入用户名称"></el-input>
        </el-form-item>
        <el-form-item label="用户状态" prop="state">
          <el-select v-model="user.state" placeholder="请选择用户状态">
            <el-option label="所有" :value="0"></el-option>
            <el-option label="在职" :value="1"></el-option>
            <el-option label="离职" :value="2"></el-option>
            <el-option label="试用期" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
          <el-button @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="base-table">
      <div class="action">
        <el-button type="primary" @click="addUserDialog">新增</el-button>
        <el-button type="danger" @click="handlePatch">批量删除</el-button>
      </div>
      <div class="pagintation">
        <el-table :data='userList' @selection-change="handleSelectionChange" max-height="800">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column v-for="item in columns" :key="item.prop" :prop="item.prop" :label="item.label" :width="item.width" :formatter="item.formatter" :sortable="item.sortable">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" @click="handleDelete(scope.$index,scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination background layout="prev, pager, next" :total="pager.total" :page-size="pager.pageSize" style="text-align: center; margin:15px 0 20px 0" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <!-- 弹窗提交表单封装为组件 -->
    <add-user :userForm="userForm" :action="action"></add-user>
  </div>
</template>

<script>
import moment from 'moment'
import AddUser from '@/components/BackStagePageComponents/AddUser.vue'
export default {
  name: 'User',
  components: {
    AddUser
  },
  data() {
    return {
      user: {
        userId: '',
        userName: '',
        state: ''
      },
      columns: [
        {
          label: '用户ID',
          prop: 'userId'
        },
        {
          label: '用户名',
          prop: 'userName'
        },
        {
          label: '用户邮箱',
          prop: 'userEmail'
        },
        {
          label: '用户角色',
          prop: 'role',
          formatter(row, column, value) {
            return {
              0: '管理员',
              1: '普通用户'
            }[value]
          }
        },
        {
          label: '用户状态',
          prop: 'state',
          formatter(row, column, value) {
            return {
              1: '在职',
              2: '离职',
              3: '试用期'
            }[value]
          }
        },
        {
          label: '注册时间',
          sortable: true,
          prop: 'createTime',
          formatter(row, column, value) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss')
          }
        },
        {
          label: '最后登录时间',
          sortable: true,
          prop: 'lastLoginTime',
          formatter(row, column, value) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss')
          }
        }
      ],
      userList: [],
      pager: {
        pageNum: 1,
        pageSize: 10
      },
      checkedUserIds: [], //选中用户列表的对象
      checkedStates: [], //选中用户列表状态
      dialogVisibleUser: false, // 是否打开新增用户dialog对话框
      userForm: {}, //传给子组件的表单信息
      action: '' //传给子组件状态 add:添加 | edit:修改
    }
  },

  mounted() {
    this.getUserList()
  },

  methods: {
    //获取用户列表数据
    async getUserList() {
      let params = { ...this.user, ...this.pager }
      try {
        const { list, page } = await this.$api.userList(params)
        this.userList = list
        this.pager = page
      } catch (error) {
        console.error(error)
      }
    },
    //查询用户
    handleQuery() {
      this.getUserList()
    },
    //重置表单
    handleReset() {
      this.$refs.form.resetFields()
    },
    //点击分页按钮
    handleCurrentChange(current) {
      this.pager.pageNum = current
      this.getUserList()
    },
    //删除用户
    async handleDelete(index, row) {
      //如果是管理员，则不能删除
      if (row.userId == 100001) {
        this.$message.error('该用户为系统管理员，不支持删除！')
        return
      }
      //如果已经离职，则不能删除
      if (row.state == 2) {
        this.$message.error('已经离职的用户不支持删除！')
        return
      }
      await this.$api.userDel({
        userIds: [row.userId] //可以单个删除，也可以批量删除
      })
      this.$message.success('删除成功！(^～^)')
      this.getUserList()
    },
    //批量删除
    async handlePatch() {
      if (this.checkedUserIds.length == 0) {
        this.$message.error('请选择要删除的用户！┐（─__─）┌')
        return
      }
      //如果是管理员，则不能删除
      if (this.checkedUserIds.includes(100001)) {
        this.$message.error('所选用户包含系统管理员，不支持删除！')
        return
      }
      //如果包含已经离职的用户，则不能删除
      if (this.checkedStates.includes(2)) {
        this.$message.error('所选包含已经离职用户，离职用户不支持删除！')
        return
      }
      await this.$api.userDel({
        userIds: [this.checkedUserIds] //可以单个删除，也可以批量删除
      })
      this.$message.success('删除成功！(^～^)')
      this.getUserList()
    },
    //获取批量删除时选择的项目
    handleSelectionChange(list) {
      // console.log(list)
      let arr = []
      let states = []
      list.map(item => {
        arr.push(item.userId)
        states.push(item.state)
      })
      this.checkedUserIds = arr
      this.checkedStates = states
    },
    //新增用户弹窗
    addUserDialog() {
      this.action = 'add'
      this.userForm = {
        //!!!使用 $nextTick() 未能解决问题，这里初始化表单
        state: 3
      }
      this.dialogVisibleUser = true
      this.$store.commit('changeDialogUser', this.dialogVisibleUser)
    },
    //编辑用户
    handleEdit(row) {
      this.action = 'edit'
      this.dialogVisibleUser = true
      this.$store.commit('changeDialogUser', this.dialogVisibleUser)
      this.$nextTick(() => {
        //子组件重置按钮是指初始化打开时的状态 ， $nextTick()是等页面渲染完毕后再添加数据
        this.userForm = row
      })
    }
  }
}
</script>

<style lang="less" scoped>
.use-container {
  height: 100%;
  background-color: #f0f2f5;
  .query-from {
    // width: 100%;
    // height: 50px;
    padding: 22px 20px 0;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #ebebeb;
  }
  .base-table {
    border-radius: 5px;
    background-color: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #ebebeb;
    .action {
      border-radius: 5px 5px 0px 0px;
      background-color: #ffffff;
      padding: 20px;
      border-bottom: 1px solid #ece8e8;
    }
    .pagintation {
      text-align: right;
      padding: 10px;
    }
  }
}
</style>