// mutations 用于操作数据 state
import storage from '../utils/storage'

export default {
  saveUserInfo(state, userInfo) {
    //将数据存储在state中
    state.userInfo = userInfo
    // console.log('调用了saveUserInfo');
    storage.setItem('userInfo', userInfo)
  },
  //记住密码，保存账号和密码
  saveAccountInfo(state, accountInfo) {
    state.accountInfo = accountInfo
    storage.setItem('accountInfo', accountInfo)
  },
  //更改侧边栏状态
  changeIsCollapse(state, isCollapse) {
    state.isCollapse = isCollapse
  },
  //更改新增用户弹窗状态
  changeDialogUser(state, dialogVisibleUser) {
    state.dialogVisibleUser = dialogVisibleUser
  },
  // 新增菜单
  changeDialogMenu(state, dialogVisibleMenu) {
    state.dialogVisibleMenu = dialogVisibleMenu
  },
  // 新增角色
  changeDialogRole(state, dialogVisibleRole) {
    state.dialogVisibleRole = dialogVisibleRole
  },
  // 权限管理
  changeDialogSetRole(state, dialogVisibleSetRole) {
    state.dialogVisibleSetRole = dialogVisibleSetRole
  },
  //部门管理
  changeDialogDept(state, dialogVisibleDept) {
    state.dialogVisibleDept = dialogVisibleDept
  },
  //申请休假
  changeDialogLeave(state, dialogVisibleLeave) {
    state.dialogVisibleLeave = dialogVisibleLeave
  },
  saveUserAction(state, actionList) {
    //将数据存储在state中
    state.actionList = actionList
    // console.log('调用了saveUserInfo');
    storage.setItem('userAction', actionList)
  },
  //通知数量
  saveNoticeCount(state, noticeCount) {
    //将数据存储在state中
    state.noticeCount = noticeCount
    storage.setItem('noticeCount', noticeCount)
  }
}
