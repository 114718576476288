<template>
  <div class="container use-container">
    <div class="query-from">
      <el-form :inline="true" :model="leave" class="demo-form-inline" ref="form">
        <el-form-item label="审批状态" prop="applyState">
          <el-select v-model="leave.applyState" placeholder="请选择审批状态">
            <el-option label="全部" value=""></el-option>
            <el-option label="待审批" :value="1"></el-option>
            <el-option label="审批中" :value="2"></el-option>
            <el-option label="审批拒绝" :value="3"></el-option>
            <el-option label="审批通过" :value="4"></el-option>
            <el-option label="作废" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
          <el-button @click="handleReset">重置 </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="base-table">
      <div class="action">
        <el-button type="primary" @click="addUserDialog">申请休假</el-button>
      </div>
      <div class="pagintation">
        <el-table :data='leaveList'>
          <el-table-column v-for="item in columns" :key="item.prop" :prop="item.prop" :label="item.label" :width="item.width" :formatter="item.formatter" :sortable="item.sortable">
          </el-table-column>
          <!-- 操作 -->
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button size="mini" @click="showDetail(scope.row)">查看</el-button>
              <!-- 作废按钮，以通过不可作废 -->
              <el-button size="mini" type="danger" @click="handleDelete(scope.$index,scope.row)" :disabled="[1,2].includes(scope.row.applyState) ? false : true ">作废</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination background layout="prev, pager, next" :total="pager.total" :page-size="pager.pageSize" style="text-align: center; margin:15px 0 20px 0" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <!-- 弹窗提交表单封装为组件 -->
    <add-leave :action='action' :leaveForm='leaveForm'></add-leave>
    <!-- 查看弹窗 -->
    <el-dialog title="申请休假详情" width="50%" :visible.sync="showDetailModal">
      <el-steps :active="detail.applyState > 2 ? 3 : detail.applyState" finish-status="success" align-center>
        <el-step title="待审批"></el-step>
        <el-step title="审批中"></el-step>
        <el-step title="审批拒绝/通过"></el-step>
      </el-steps>
      <el-form label-width="120px" class="detail-form">
        <el-form-item label="休假类型：">
          {{detail.applyTypeName}}
        </el-form-item>
        <el-form-item label="休假时间：">
          {{detail.time}}
        </el-form-item>
        <el-form-item label="休假时长：">
          {{detail.leaveTime}}
        </el-form-item>
        <el-form-item label="休假原因：">
          {{detail.reasons}}
        </el-form-item>
        <el-form-item label="审批转态：">
          {{detail.applyStateName}}
        </el-form-item>
        <el-form-item label="审批人：">
          {{detail.curAuditUserName}}
        </el-form-item>
        <el-form-item v-if="detail.applyState > 2 ? true : false" label="备注：">
          <div v-for="item in detail.remarkArr">
            <span>{{item.userName}}：{{item.remark}}</span>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import AddLeave from '@/components/BackStagePageComponents/AddLeave.vue'
export default {
  name: 'Leave',
  components: {
    AddLeave
  },
  data() {
    return {
      leave: {
        applyState: ''
      },
      columns: [
        {
          label: '单号',
          prop: 'orderNo'
        },
        {
          label: '休假时间',
          prop: '',
          width: '118px',
          formatter(row, column, value) {
            return moment(row.startTime).format('YYYY-MM-DD') + '到' + moment(row.endTime).format('YYYY-MM-DD')
          }
        },
        {
          label: '休假时长',
          prop: 'leaveTime'
        },
        {
          label: '休假类型',
          prop: 'applyType',
          formatter(row, column, value) {
            return {
              1: '事假',
              2: '调休',
              3: '年假'
            }[value]
          }
        },
        {
          label: '休假原因',
          prop: 'reasons'
        },
        {
          label: '申请时间',
          prop: 'createTime',
          sortable: true,
          width: 110,
          formatter(row, column, value) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss')
          }
        },
        {
          label: '审批人',
          prop: 'auditUsers'
        },
        {
          label: '当前审批人',
          prop: 'curAuditUserName'
        },
        {
          label: '审批状态',
          prop: 'applyState',
          formatter(row, column, value) {
            return {
              1: '待审批',
              2: '审批中',
              3: '审批拒绝',
              4: '审批通过',
              5: '作废'
            }[value]
          }
        }
      ],
      leaveList: [],
      pager: {
        pageNum: 1,
        pageSize: 10
      },
      dialogVisibleLeave: false, // 是否打开申请休假 dialog 对话框
      showDetailModal: false, // 是否打开查看对话框
      leaveForm: {}, //传给子组件的初始化表单
      detail: {}, //查看详情数据
      action: 'add' //传给子组件状态 add:添加 | edit:修改
    }
  },

  mounted() {
    this.getApplyList()
  },

  methods: {
    //获取审批列表数据
    async getApplyList() {
      let params = { ...this.leave, ...this.pager }
      try {
        const { list, page } = await this.$api.getApplyList(params)
        this.leaveList = list
        this.pager = page
      } catch (error) {
        console.error(error)
      }
    },
    //查询用户
    handleQuery() {
      this.getApplyList()
    },
    //重置表单
    handleReset() {
      this.$refs.form.resetFields()
    },
    //点击分页按钮
    handleCurrentChange(current) {
      this.pager.pageNum = current
      this.getApplyList()
    },
    //作废
    async handleDelete(index, row) {
      try {
        const id = row._id
        console.log(id)
        let params = { id, action: 'delete' }
        let res = await this.$api.leaveOperate(params)
        if (res) {
          this.$message.success('休假申请作废成功！(^～^)')
          this.getApplyList()
        }
      } catch (error) {
        this.$message.error(`休假申请作废失败！error：${error}`)
      }
    },
    //新增用户弹窗
    addUserDialog() {
      // this.action = 'add'
      this.leaveForm = {
        //申请休假表单事件
        applyType: 1,
        startTime: '',
        endTime: '',
        leaveTime: '0天',
        reasons: ''
      }
      this.dialogVisibleLeave = true
      this.$store.commit('changeDialogLeave', this.dialogVisibleLeave)
    },
    //查看
    showDetail(row) {
      this.showDetailModal = true
      row.applyTypeName = {
        1: '事假',
        2: '调休',
        3: '年假'
      }[row.applyType]
      row.time = moment(row.startTime).format('YYYY-MM-DD') + ' 到 ' + moment(row.endTime).format('YYYY-MM-DD')
      row.applyStateName = {
        1: '待审批',
        2: '审批中',
        3: '审批拒绝',
        4: '审批通过',
        5: '作废'
      }[row.applyState]
      const [...auditLogs] = row.auditLogs
      let remarkArr = auditLogs.filter(item => {
        item.remark !== ''
        return item
      })
      row.remarkArr = remarkArr
      this.detail = row
    }
  }
}
</script>

<style lang="less" scoped>
.use-container {
  height: 100%;
  background-color: #f0f2f5;
  .query-from {
    // width: 100%;
    // height: 50px;
    padding: 22px 20px 0;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #ebebeb;
  }
  .base-table {
    border-radius: 5px;
    background-color: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #ebebeb;
    .action {
      border-radius: 5px 5px 0px 0px;
      background-color: #ffffff;
      padding: 20px;
      border-bottom: 1px solid #ece8e8;
    }
    .pagintation {
      text-align: right;
      padding: 10px;
    }
  }
  .detail-form {
    margin-top: 0.1875rem;
    margin-left: 1rem;
  }
}
</style>