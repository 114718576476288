// axios 二次封装
import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router/index.js'
import storage from '../utils/storage.js'
const TOCKEN_INVALID = 'Tocken认证失败，请重新登录！(╥﹏╥)'
const NETWORK_ERROR = '网络请求异常，请稍后再试！(╥﹏╥)'
// 创建axios实例对象，添加全局配置
const service = axios.create({
  baseURL: '/api',
  timeout: 8000
})
//请求拦截
service.interceptors.request.use(req => {
  //前端拿到 token 并在请求时携带发送
  const { token } = storage.getItem('userInfo')
  const headers = req.headers
  if (!headers.Authorization) headers.Authorization = 'Bearer ' + token
  //返回 req 让程序继续向下执行
  return req
})

//响应拦截
service.interceptors.response.use(res => {
  const { code, data, msg } = res.data
  if (code === 200) {
    return data
  } else if (code === 50001) {
    // Tocken 异常情况-->返回登录页面
    Message.error(TOCKEN_INVALID)
    //跳转到登录页面
    setTimeout(() => {
      router.push('/login')
    }, 1500)

    //抛出异常
    return Promise.reject(TOCKEN_INVALID)
  } else {
    Message.error(msg || NETWORK_ERROR)
    //抛出异常
    return Promise.reject(msg || NETWORK_ERROR)
  }
})

//请求核心 区分请求是 get 还是 post ，前端无需担心传入的值为params 还是 data
function request(options) {
  // 若是没传 则默认给get请求
  options.method = options.method || 'get'
  if (options.method.toLowerCase() === 'get') {
    options.params = options.data
  }
  return service(options)
}
export default request
