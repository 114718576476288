<template>
  <div class="com-container">
    <!-- 热销农作物占比 -->
    <div class="com-chart" ref="hot_ref">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hot',

  data() {
    return {
      chartInstane: null,
      allData: null //从服务器获取数据
    }
  },

  mounted() {
    //初始化图表
    this.initChart()
    this.getData()
    //监听页面变化
    window.addEventListener('resize', this.screenAdapter)
    //第一次加载，主动调用
    this.screenAdapter()
  },
  destroyed() {
    //销毁监听事件
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    initChart() {
      this.chartInstane = this.$echarts.init(this.$refs.hot_ref, 'chalk')
      const initOption = {
        title: {
          text: '▎热销农作物占比',
          left: '5%'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}:{c} ({d}%)'
        },
        color: ['#37a2da', '#32c5e9', '#9fe6b8', '#ffdb5c', '#ff9f7f', '#fb7293', '#e7bcf3', '#8378ea'],
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['pie', 'funnel']
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        calculable: true
      }
      this.chartInstane.setOption(initOption)
    },
    // 获取数据
    async getData() {
      const res = await this.$api.getHotData()
      this.allData = res
      //更新数据
      this.updateChart()
    },
    //更新处理数据
    updateChart() {
      const dataOption = {
        series: [
          {
            name: '热销农作物占比',
            type: 'pie',
            // radius: [40, 150],
            roseType: 'area',
            data: this.allData
          }
        ]
      }
      this.chartInstane.setOption(dataOption)
    },
    //图表响应式变化
    screenAdapter() {
      const fontSize = (this.$refs.hot_ref.offsetWidth / 100) * 3
      const innerRadius = fontSize * 1.5
      const outterRadius = innerRadius * 4
      const adapterOption = {
        type: 'pie',
        series: [
          {
            type: 'pie',
            radius: [innerRadius, outterRadius],
            label: {
              fontSize: fontSize / 1.2
            }
          }
        ]
      }
      this.chartInstane.setOption(adapterOption)
      this.chartInstane.resize()
    }
  }
}
</script>

<style lang="less" scoped>
.com-container {
  position: absolute;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 87%;
  overflow: hidden;

  .com-chart {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>