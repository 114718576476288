<template>
  <!-- 账户信息 -->
  <div class="account-container">
    <div class="info-box">
      <div class="tab-box">
        <!-- 账户信息 -->
        <el-descriptions class="margin-top" title="账户信息" :column="1" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              用户名
            </template>
            {{userForm.userName}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              手机号
            </template>
            {{userForm.mobile}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              邮箱
            </template>
            {{userForm.userEmail}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              密码
            </template>
            *********
            <el-button type='primary' size="mini" style="float:right" @click="dialog = true">修改</el-button>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-tickets"></i>
              系统角色
            </template>
            <el-tag :type="userForm.role == 0 ? 'warning':'success'" size="small">{{userForm.role == 0 ? '管理员':'普通用户'}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-tickets"></i>
              状态
            </template>
            <el-tag :type="userForm.state == 1 ? 'success': userForm.state == 3 ? 'info':'danger'" size="small">{{userForm.state == 1 ? '在职': userForm.state == 3 ? '试用期':'离职'}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-tickets"></i>
              岗位
            </template>
            {{userForm.job}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-tickets"></i>
              标签
            </template>
            <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleCloseTag(tag)">
              {{tag}}
            </el-tag>
            <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
          </el-descriptions-item>
        </el-descriptions>
        <!-- 右侧修改密码 -->
        <el-drawer title="修改账号密码" :before-close="handleClose" :visible.sync="dialog" direction="rtl" custom-class="demo-drawer" ref="drawer">
          <div class="demo-drawer__content">
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm">
              <el-form-item label="请输入新密码" :label-width="formLabelWidth" prop="pass">
                <el-input v-model="ruleForm.pass" autocomplete="off" style="width:80%" type="password">
                </el-input>
              </el-form-item>
              <el-form-item label="请再次输入新密码" :label-width="formLabelWidth" prop="checkPass">
                <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" style="width:80%"></el-input>
              </el-form-item>
            </el-form>
            <div class="demo-drawer__footer">
              <el-button @click="cancelForm('ruleForm')" style="width:50%">取 消</el-button>
              <el-button type="primary" style="width:50%" @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>
            </div>
          </div>
        </el-drawer>
      </div>
      <div class="map-box" ref="allmap"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accountinfo',

  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      size: 'mini',
      userForm: {
        userName: this.$store.state.userInfo.userName,
        mobile: this.$store.state.userInfo.mobile,
        userEmail: this.$store.state.userInfo.userEmail,
        job: this.$store.state.userInfo.job,
        role: this.$store.state.userInfo.role,
        state: this.$store.state.userInfo.state
      },
      dynamicTags: ['积极', '辣~', '开心(^～^)', '- ̗̀(๑ᵔ⌔ᵔ๑)'],
      inputVisible: false,
      inputValue: '',
      ruleForm: {
        pass: '',
        checkPass: ''
      },
      rules: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }]
      },
      dialog: false,
      loading: false,
      formLabelWidth: '150px',
      timer: null
    }
  },

  mounted() {
    this.map()
  },

  methods: {
    //关闭标签
    handleCloseTag(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
    },
    // 标签输入文字
    showInput() {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue) {
        this.dynamicTags.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    //关闭右侧
    handleClose(done) {
      if (this.loading) {
        return
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let params = {}
          params.userId = this.$store.state.userInfo.userId
          params.changePwd = this.ruleForm.pass
          params.action = 'changePwd'
          this.$confirm('确定要提交表单吗？')
            .then(_ => {
              this.loading = true
              this.timer = setTimeout(() => {
                //提交表单到后端
                let res = this.$api.operateUsers(params)
                if (res) {
                  this.$message.success(`密码修改成功！`)
                } else {
                  this.$message.error(`密码修改失败！`)
                }
                done()
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false
                }, 400)
                //清空
                this.$refs.ruleForm.resetFields()
              }, 1000)
            })
            .catch(_ => {
              this.$message.fail(`密码修改失败！`)
              return false
            })
        } else {
          return false
        }
      })
    },
    cancelForm(formName) {
      this.$refs[formName].resetFields()
      this.loading = false
      this.dialog = false
      clearTimeout(this.timer)
    },
    //使用百度地图
    map() {
      let map = new window.BMap.Map(this.$refs.allmap) // 创建Map实例
      map.centerAndZoom(new window.BMap.Point(116.404, 39.915), 11) // 初始化地图,设置中心点坐标和地图级别
      //使用地址解析进行定位标注点
      var geolocation = new BMap.Geolocation()
      var address
      //获取当前地理位置
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          var mk = new BMap.Marker(r.point)
          map.addOverlay(mk)
          map.panTo(r.point)
          address = [r.point.lng, r.point.lat]
        } else {
          this.$message.error(`failed=>${this.getStatus()}`)
        }
      })
      var myGeo = new BMap.Geocoder()
      myGeo.getPoint(address, function (point) {
        if (point) {
          map.centerAndZoom(point, 16)
          map.addOverlay(new BMap.Marker(point, { title: address }))
        } else {
          this.$message.error('地址解析失败！')
        }
      })
      //添加控件
      map.addControl(
        new window.BMap.MapTypeControl({
          // 添加地图类型控件
          mapTypes: [window.BMAP_NORMAL_MAP, window.BMAP_HYBRID_MAP]
        })
      )
      // map.setCurrentCity('北京') // 设置地图显示的城市 此项是必须设置的
      map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
    }
  }
}
</script>

<style lang="less" scoped>
.account-container {
  width: 100%;
  height: 90vh;
  padding-top: 0.3rem;
  .info-box {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .tab-box {
      // padding-top: 50px;
      padding-right: 0.3125rem;
      padding-left: 0.625rem;
      width: 50%;
      height: 100%;
    }
    .map-box {
      width: 50%;
      height: 60%;
      margin-top: 0.5rem;
      padding-right: 0.3125rem;
      // border: 1px solid red;
    }
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .demo-drawer {
    position: relative;
    .demo-drawer__footer {
      display: flex;
      box-sizing: border-box;
      position: absolute;
      bottom: 0.25rem;
      left: 50%;
      transform: translateX(-50%);
      width: 95%;
      .el-buttom {
        flex: 1;
      }
    }
  }
}
</style>