<template>
  <div class="com-container">
    <!-- 各大棚产量分析 -->
    <div class="com-chart" ref="yield_ref">

    </div>
  </div>
</template>

<script>
export default {
  name: 'Yield',

  data() {
    return {
      chartInstane: null,
      allData: null, //从服务器获取数据
      currentIndex: 0,
      timerId: null
    }
  },

  mounted() {
    //初始化图表
    this.initChart()
    this.getData()
    //监听页面变化
    window.addEventListener('resize', this.screenAdapter)
    //第一次加载，主动调用
    this.screenAdapter()
  },
  destroyed() {
    //销毁监听事件
    window.removeEventListener('resize', this.screenAdapter)
    clearInterval(this.timerId)
  },
  methods: {
    //初始化 echart 对象
    initChart() {
      this.chartInstane = this.$echarts.init(this.$refs.yield_ref, 'chalk')
      const initOption = {
        // legend: {
        //   show: true,
        //   orient: 'vertical'
        // }
      }
      this.chartInstane.setOption(initOption)
      this.chartInstane.on('mouseover', () => {
        clearInterval(this.timerId)
      })
      this.chartInstane.on('mouseout', () => {
        this.startInterval()
      })
    },
    //获取数据
    async getData() {
      const res = await this.$api.getYield()
      this.allData = res
      //更新数据
      this.updateChart()
      this.startInterval()
    },
    //更新处理数据
    updateChart() {
      //各饼形图中心点数据
      const centerArr = [
        ['18%', '30%'],
        ['50%', '30%'],
        ['82%', '30%'],
        ['34%', '75%'],
        ['66%', '75%']
      ]
      //圆环颜色数据
      const colorArr = [
        ['#4FF778', '#0BA82C'],
        ['#E5DD45', '#E8B11C'],
        ['#E8821C', '#E55445'],
        ['#5052EE', '#AB6EE5'],
        ['#23E5E5', '#2E72BF']
      ]
      //数据分割
      const start = this.currentIndex * 5
      const end = (this.currentIndex + 1) * 5
      const showData = this.allData.slice(start, end)
      const seriesArr = showData.map((item, index) => {
        return {
          type: 'pie',
          // radius: [110, 100],
          center: centerArr[index],
          label: {
            position: 'center',
            color: colorArr[index][0]
          },
          data: [
            {
              name: item.name + '\n\n' + item.sales,
              value: item.sales,
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: colorArr[index][0]
                  },
                  {
                    offset: 1,
                    color: colorArr[index][1]
                  }
                ])
              }
            },
            {
              value: item.stock,
              itemStyle: {
                color: '#333843'
              }
            }
          ],
          hoverAnimation: false, //关闭鼠标移入动画
          labelLine: {
            show: false //隐藏指示线
          }
        }
      })
      const dataOption = {
        series: seriesArr
      }
      this.chartInstane.setOption(dataOption)
    },
    //图表响应式变化
    screenAdapter() {
      const fontSize = (this.$refs.yield_ref.offsetWidth / 100) * 3.6
      const innerRadius = fontSize * 2.8
      const outterRadius = innerRadius * 1.125
      const adapterOption = {
        series: [
          {
            type: 'pie',
            radius: [outterRadius, innerRadius],
            label: {
              fontSize: fontSize / 1.2
            }
          },
          {
            type: 'pie',
            radius: [outterRadius, innerRadius],
            label: {
              fontSize: fontSize / 1.2
            }
          },
          {
            type: 'pie',
            radius: [outterRadius, innerRadius],
            label: {
              fontSize: fontSize / 1.2
            }
          },
          {
            type: 'pie',
            radius: [outterRadius, innerRadius],
            label: {
              fontSize: fontSize / 1.2
            }
          },
          {
            type: 'pie',
            radius: [outterRadius, innerRadius],
            label: {
              fontSize: fontSize / 1.2
            }
          }
        ]
      }
      this.chartInstane.setOption(adapterOption)
      this.chartInstane.resize()
    },
    //数据切换定时器
    startInterval() {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        this.currentIndex++
        if (this.currentIndex > 1) {
          this.currentIndex = 0
        }
        this.updateChart()
      }, 5000)
    }
  }
}
</script>

<style lang="less" scoped>
.com-container {
  position: absolute;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 87%;
  overflow: hidden;

  .com-chart {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>