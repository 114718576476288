<template>
  <div class="container use-container">
    <div class="query-from">
      <el-form :inline="true" :model="queryForm" class="demo-form-inline" ref="form">
        <el-form-item label="菜单名称" prop="menuName">
          <el-input v-model="queryForm.menuName" placeholder="请输入菜单名称"></el-input>
        </el-form-item>
        <el-form-item label="菜单状态" prop="menuState">
          <el-select v-model="queryForm.menuState" placeholder="请选择菜单状态">
            <el-option label="正常" :value="1"></el-option>
            <el-option label="停用" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
          <el-button @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="base-table">
      <div class="action">
        <el-button type="primary" @click="handleAdd(1)">新增</el-button>
      </div>
      <div class="pagintation">
        <el-table :data='menuList' row-key="_id" :tree-props="{children:'children',}" max-height="800">
          </el-table-column>
          <el-table-column v-for="item in columns" :key="item.prop" :prop="item.prop" :label="item.label" :width="item.width" :formatter="item.formatter" :sortable="item.sortable">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleAdd(2,scope.row)" type="primary">新增</el-button>
              <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 弹窗提交表单封装为组件 -->
    <add-menu :menuForm="menuForm" :action="action" :menuList="menuList"></add-menu>
  </div>
</template>

<script>
import moment from 'moment'
import bus from '@/utils/eventBus.js'
import AddMenu from '@/components/BackStagePageComponents/AddMenu.vue'
export default {
  name: 'User',
  components: {
    AddMenu
  },
  data() {
    return {
      queryForm: {},
      columns: [
        {
          label: '菜单名称',
          prop: 'menuName',
          width: 130
        },
        {
          label: '图标',
          prop: 'icon',
          width: 120
        },
        {
          label: '菜单类型',
          prop: 'menuType',
          width: 95,
          formatter(row, column, value) {
            return {
              1: '菜单',
              2: '按钮'
            }[value]
          }
        },
        {
          label: '权限标识',
          prop: 'menuCode',
          width: 120
        },
        {
          label: '路由地址',
          prop: 'path',
          width: 120
        },
        {
          label: '组件路径',
          prop: 'component', //后期可以动态加载路由
          width: 120
        },
        {
          label: '菜单状态',
          prop: 'menuState',
          width: 95,
          formatter(row, column, value) {
            return {
              1: '正常',
              2: '停用'
            }[value]
          }
        },
        {
          label: '创建时间',
          sortable: true,
          prop: 'createTime',
          formatter(row, column, value) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss')
          }
        }
      ],
      menuList: [],
      dialogVisibleMenu: false, // 是否打开新增用户dialog对话框
      menuForm: {}, //传给子组件的表单信息
      action: '' //传给子组件状态 add:添加 | edit:修改
    }
  },

  mounted() {
    this.getMenuList()
  },

  methods: {
    //getMenuList
    async getMenuList() {
      try {
        let list = await this.$api.meunList(this.queryForm)
        this.menuList = list
      } catch (error) {
        throw new Error(error)
      }
    },
    //查询菜单
    handleQuery() {
      this.getMenuList()
    },
    //重置表单
    handleReset() {
      this.$refs.form.resetFields()
    },
    //删除菜单
    async handleDelete(row) {
      let _id = row._id
      let res = await this.$api.menuSubmit({ _id, action: 'delete' })
      this.$message.success('删除成功！')
      this.getMenuList()
    },
    //编辑菜单
    handleEdit(row) {
      this.action = 'edit'
      this.dialogVisibleMenu = true
      this.$store.commit('changeDialogMenu', this.dialogVisibleMenu)
      // if (row.menuType == 2) {   //to-do 按钮编辑 bug 已选中按钮状态，但未展示
      //   this.menuForm = {
      //     menuType: 2
      //   }
      // }
      this.$nextTick(() => {
        //子组件重置按钮是指初始化打开时的状态 ， $nextTick()是等页面渲染完毕后再添加数据
        this.menuForm = row
      })
    },
    //新增菜单
    handleAdd(type, row) {
      this.menuForm = {
        //!!!使用 $nextTick() 未能解决问题，这里初始化表单
        parentId: [null], //默认为空值
        menuType: 1,
        menuState: 1 //传递一个默认值
      }
      this.dialogVisibleMenu = true
      this.action = 'add'
      this.$store.commit('changeDialogMenu', this.dialogVisibleMenu)
      // 若是第二个新增，则需要传有关数据给子组件 将 parentId 传递给子组件
      if (type == 2) {
        this.menuForm.parentId = [...row.parentId, row._id].filter(item => item)
      }
    }
  },
  watch: {
    menuList: {
      //监听 menuList 数据变化，并将数据传入到 Aside.vue 中
      immediate: true,
      deep: true,
      handler: function (newVal, oldVal) {
        bus.$emit('getMenuList', newVal)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.use-container {
  height: 100%;
  background-color: #f0f2f5;
  .query-from {
    // width: 100%;
    // height: 50px;
    padding: 22px 20px 0;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #eee;
  }
  .base-table {
    border-radius: 5px;
    background-color: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #ebebeb;
    .action {
      border-radius: 5px 5px 0px 0px;
      background-color: #ffffff;
      padding: 20px;
      border-bottom: 1px solid #ece8e8;
    }
    .pagintation {
      text-align: right;
      padding: 10px;
    }
  }
}
</style>