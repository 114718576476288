<template>
  <div class="container use-container">
    <div class="query-from">
      <el-form :inline="true" :model="queryForm" class="demo-form-inline" ref="form">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="queryForm.roleName" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
          <el-button @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="base-table">
      <div class="action">
        <el-button type="primary" @click="handleAdd" v-has="'role-create'">创建</el-button>
      </div>
      <div class="pagintation">
        <el-table :data='roleList' max-height="800">
          </el-table-column>
          <el-table-column v-for="item in columns" :key="item.prop" :prop="item.prop" :label="item.label" :width="item.width" :formatter="item.formatter" :sortable="item.sortable">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.row)" type="primary" v-has="'role-edit'">编辑</el-button>
              <el-button size="mini" @click="handleSet(scope.row)" v-has="'role-setting'">权限设置</el-button>
              <el-button size="mini" type="danger" @click="handleDelete(scope.row)" v-has="'role-delete'">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination background layout="prev, pager, next" :total="pager.total" :page-size="pager.pageSize" style="text-align: center; margin:15px 0 20px 0" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <!-- 弹窗提交表单封装为组件 -->
    <add-role :action="action" :roleForm="roleForm"></add-role>
    <set-role :setRoleFrom='setRoleFrom' :menuList="menuList"></set-role>
  </div>
</template>

<script>
import moment from 'moment'
import AddRole from '@/components/BackStagePageComponents/AddRole.vue'
import SetRole from '@/components/BackStagePageComponents/SetRole.vue'
export default {
  name: 'Role',
  components: {
    AddRole,
    SetRole
  },
  data() {
    return {
      queryForm: {},
      columns: [
        {
          label: '角色名称',
          prop: 'roleName',
          width: 150
        },
        {
          label: '备注',
          prop: 'remark',
          width: 150
        },
        {
          label: '权限列表',
          prop: 'permissionList',
          formatter: (row, column, value) => {
            let names = []
            let list = value.halfCheckedKeys || []
            list.map(key => {
              let name = this.actionMap[key]
              if (key && name) names.push(name)
            })
            return names.join(',')
          }
        },
        {
          label: '创建时间',
          prop: 'createTime',
          sortable: true,
          formatter(row, column, value) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss')
          }
        },
        {
          label: '更新时间',
          prop: 'updateTime',
          sortable: true,
          formatter(row, column, value) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss')
          }
        }
      ],
      roleList: [],
      pager: {
        total: 0,
        pageSize: 10
      },
      dialogVisibleRole: false, // 是否打开新增角色dialog对话框
      dialogVisibleSetRole: false, //是否打开权限编辑对话框
      roleForm: {}, //传给子组件的表单信息
      setRoleFrom: {}, //传给权限设置表单信息
      action: '', //传给子组件状态 add:添加 | edit:修改
      menuList: [], //传给子组件的菜单列表
      actionMap: {} //菜单字典映射表
    }
  },

  mounted() {
    this.getRoleList()
    this.getMenuList()
  },

  methods: {
    //getRoleList
    async getRoleList() {
      try {
        let { list, page } = await this.$api.getRoleList({ ...this.queryForm, ...this.pager })
        this.roleList = list
        this.pager = page
      } catch (error) {
        throw new Error(error)
      }
    },
    //获取菜单列表 | 菜单初始化，权限列表源数据获取
    async getMenuList() {
      try {
        let list = await this.$api.meunList(this.queryForm)
        this.menuList = list
        this.getActionMap(list)
      } catch (error) {
        throw new Error(error)
      }
    },
    //权限列表源数据处理 --> key 字典 映射
    getActionMap(list) {
      let actionMap = {}
      const deep = arr => {
        while (arr.length) {
          let item = arr.pop()
          if (item.children && item.menuName) {
            actionMap[item._id] = item.menuName
          }
          if (item.children && !item.action) {
            deep(item.children)
          }
        }
      }
      deep(JSON.parse(JSON.stringify(list)))
      this.actionMap = actionMap
    },
    //点击分页按钮
    handleCurrentChange(current) {
      this.pager.pageNum = current
      this.getRoleList()
    },
    //查询菜单
    handleQuery() {
      this.getRoleList()
    },
    //重置表单
    handleReset() {
      this.$refs.form.resetFields()
    },
    //删除角色
    async handleDelete(row) {
      let _id = row._id
      let res = await this.$api.roleOperate({ _id, action: 'delete' })
      this.$message.success('删除成功！')
      this.getRoleList()
    },
    //编辑角色
    handleEdit(row) {
      this.action = 'edit'
      this.dialogVisibleRole = true
      this.$store.commit('changeDialogRole', this.dialogVisibleRole)
      this.$nextTick(() => {
        //子组件重置按钮是指初始化打开时的状态 ， $nextTick()是等页面渲染完毕后再添加数据
        this.roleForm = row
      })
    },
    //新增角色
    handleAdd() {
      this.roleForm = {}
      this.dialogVisibleRole = true
      this.action = 'add'
      this.$store.commit('changeDialogRole', this.dialogVisibleRole)
    },
    // 权限设置
    handleSet(row) {
      this.dialogVisibleSetRole = true
      this.$store.commit('changeDialogSetRole', this.dialogVisibleSetRole)
      this.setRoleFrom = row
      // console.log(row)
    }
  },
  watch: {}
}
</script>

<style lang="less" scoped>
.use-container {
  height: 100%;
  background-color: #f0f2f5;
  .query-from {
    // width: 100%;
    // height: 50px;
    padding: 22px 20px 0;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #ebebeb;
  }
  .base-table {
    border-radius: 5px;
    background-color: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #ebebeb;
    .action {
      border-radius: 5px 5px 0px 0px;
      background-color: #ffffff;
      padding: 20px;
      border-bottom: 1px solid #ece8e8;
    }
    .pagintation {
      text-align: right;
      padding: 10px;
    }
  }
}
</style>