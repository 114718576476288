<template>
  <div class="com-container">
    <!-- 降水量组件 -- 柱状图 -->
    <div class="com-chart" ref="precipitation_ref">

    </div>
  </div>
</template>

<script>
export default {
  name: 'Precipitation',

  data() {
    return {
      chartInstane: null,
      allData: null, //从服务器获取数据
      startValue: 0,
      endValue: 9,
      timerId: null
    }
  },

  mounted() {
    //初始化图表
    this.initChart()
    this.getData()
    //监听页面变化
    window.addEventListener('resize', this.screenAdapter)
    //第一次加载，主动调用
    this.screenAdapter()
  },
  destroyed() {
    //销毁监听事件
    window.removeEventListener('resize', this.screenAdapter)
    clearInterval(this.timerId)
  },
  methods: {
    initChart() {
      this.chartInstane = this.$echarts.init(this.$refs.precipitation_ref, 'chalk')
      const initOption = {
        grid: {
          top: '15%',
          left: '5%',
          rigth: '5%',
          bottom: '5%',
          containLabel: true
        },
        tooltip: {
          show: true,
          formatter: '{b}日：{c}(mm/mim)'
        },
        xAxis: {
          type: 'category',
          name: '月/日'
        },
        yAxis: {
          type: 'value',
          name: '降水量(mm/mim)',
          nameTextStyle: {
            color: '#fff'
          }
        },
        series: [
          {
            type: 'bar'
          }
        ]
      }
      this.chartInstane.setOption(initOption)
      //鼠标移入、移出
      this.chartInstane.on('mouseover', () => {
        clearInterval(this.timerId)
      })
      this.chartInstane.on('mouseout', () => {
        this.startInterval()
      })
    },
    //获取数据
    async getData() {
      const res = await this.$api.getRainfall()
      this.allData = res
      // this.allData.sort((a, b) => {
      //   return b.value - a.value
      // })
      //更新数据
      this.updateChart()
      this.startInterval()
    },
    //更新数据
    updateChart() {
      const colorArr = [
        ['#0BA82C', '#4FF778'],
        ['#2E72BF', '#23E5E5'],
        ['#5052EE', '#AB6EE5']
      ]
      //x轴数组
      const provinceArr = this.allData.map(item => {
        return item.name
      })
      const valueArr = this.allData.map(item => {
        return item.value
      })
      const dataOption = {
        xAxis: {
          data: provinceArr
        },
        dataZoom: {
          //配置区域缩放
          show: false,
          startValue: this.startValue,
          endValue: this.endValue
        },
        series: [
          {
            data: valueArr,
            itemStyle: {
              color: arg => {
                let targetColorArr = null
                if (arg.value > 300) {
                  targetColorArr = colorArr[0]
                } else if (arg.value > 200) {
                  targetColorArr = colorArr[1]
                } else {
                  targetColorArr = colorArr[2]
                }
                return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: targetColorArr[0]
                  },
                  {
                    offset: 1,
                    color: targetColorArr[0]
                  }
                ])
              }
            }
          }
        ]
      }
      this.chartInstane.setOption(dataOption)
    },
    //图表响应式变化
    screenAdapter() {
      const fontSize = (this.$refs.precipitation_ref.offsetWidth / 100) * 3.6
      const adapterOption = {
        series: [
          {
            barWidth: fontSize,
            itemStyle: {
              barBorderRadius: [fontSize / 2, fontSize / 2, 0, 0]
            }
          }
        ],
        yAxis: {
          nameTextStyle: {
            fontSize: fontSize > 48 ? 24 : fontSize / 2
          }
        }
      }
      this.chartInstane.setOption(adapterOption)
      this.chartInstane.resize()
    },
    // 区域缩放定时器，改变startValue 和 endValue
    startInterval() {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        this.startValue++
        this.endValue++
        if (this.endValue > this.allData.length - 1) {
          this.startValue = 0
          this.endValue = 9
        }
        this.updateChart()
      }, 2000)
    }
  }
}
</script>

<style lang="less" scoped>
.com-container {
  position: absolute;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 87%;
  overflow: hidden;
  .com-chart {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>