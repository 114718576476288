// 新增用户 dialog 对话框
<template>
  <div class="add-user-from">
    <el-dialog :visible.sync="dialogVisibleUser" title="新增用户" :before-close="handleClose">
      <el-form ref="addUserForm" :model="userForm" label-width="100px" :rules="rules">
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="userForm.userName" placeholder="请输入用户名" :disabled="action == 'edit'"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="userEmail">
          <el-input v-model="userForm.userEmail" placeholder="请输入邮箱" :disabled="action == 'edit'">
          </el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="userForm.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="岗位" prop="job">
          <el-input v-model="userForm.job" placeholder="请输入岗位"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="userForm.state" placeholder="请选择用户状态">
            <el-option :value="1" label="在职"></el-option>
            <el-option :value="2" label="离职"></el-option>
            <el-option :value="3" label="试用期"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="系统角色" prop="roleList">
          <el-select v-model="userForm.roleList" placeholder="请选择用户系统角色" multiple style="width:100%">
            <el-option v-for="role in rolesAllList" :key="role._id" :label="role.roleName" :value="role._id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门" prop="deptId">
          <el-cascader v-model="userForm.deptId" :options="deptList" :props="{checkStrictly:true,value:'_id',label:'deptName'}" clearable placeholder="请选择所属部门" style="width:100%">
          </el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel('addUserForm')">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'AddUser',
  props: ['action', 'userForm'],
  data() {
    return {
      dialogVisibleUser: false,
      // userForm: {
      //   state: 3
      // }, //表单对象
      rules: {
        userName: [
          {
            required: true,
            message: '请输入用户名称',
            trigger: 'blur'
          }
        ],
        userEmail: [
          {
            required: true,
            message: '请输入邮箱',
            trigger: 'blur'
          },
          {
            pattern: /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/,
            message: '请输入正确的邮箱地址',
            trigger: 'blur'
          }
        ],
        mobile: [
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号格式',
            trigger: 'blur'
          }
        ],
        deptId: [
          {
            required: true,
            message: '部门不能为空',
            trigger: 'blur'
          }
        ]
      }, //表单验证规则
      rolesAllList: [], //新增用户：角色名称列表
      deptList: [] //部门列表
    }
  },

  mounted() {
    this.getRolesAlllist()
    this.getDeptList()
  },

  methods: {
    //关闭弹窗提示
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
          this.dialogVisibleUser = false
        })
        .catch(_ => {})
    },
    //表单控件关闭弹窗提示 -->取消按钮询问事件
    handleCancel(formName) {
      // console.log(this.action)
      //需要对 action 的状态进行判断 如果为 edit 则不清空表单
      if (this.action == 'add') {
        this.$confirm('确认取消？（取消后当前表单数据会丢失！）')
          .then(_ => {
            this.$refs[formName].resetFields() //重置当前表单
            this.dialogVisibleUser = false
          })
          .catch(_ => {})
      } else {
        this.$confirm('确认取消？')
          .then(_ => {
            this.dialogVisibleUser = false
          })
          .catch(_ => {})
      }
    },
    // 点击确定按钮提交，关闭弹窗
    handleSubmit() {
      this.$refs.addUserForm.validate(async valid => {
        if (valid) {
          let params = this.userForm
          params.action = this.action // 指定 action 添加/修改
          let res = await this.$api.operateUsers(params)
          if (res) {
            this.dialogVisibleUser = false
            if (this.action == 'add') {
              this.$message.success('用户创建成功！(^～^)')
            } else {
              this.$message.success('用户修改成功！(^～^)')
            }
            this.$parent.getUserList()
          }
        }
      })
    },
    //新增用户：角色名称列表
    async getRolesAlllist() {
      this.rolesAllList = await this.$api.rolesAllList()
      // console.log(this.rolesAllList)
    },
    //新增用户：部门列表获取
    async getDeptList() {
      this.deptList = await this.$api.deptList()
      // console.log(this.deptList)
    }
  },
  watch: {
    '$store.state.dialogVisibleUser': {
      immediate: true,
      deep: true,
      handler: function (newVal, oldVal) {
        this.dialogVisibleUser = newVal
      }
    },
    //使用数据监听，监听dialogVisibleUser数据变化，变化提交到vuex中
    dialogVisibleUser: {
      handler: function (newVal, oldVal) {
        this.$store.commit('changeDialogUser', this.dialogVisibleUser)
      }
    },
    //对父组件传递过来的 userFrom 进行数据监听
    userForm: {
      handler: function (newVal, oldVal) {
        this.userForm = newVal
      }
    },
    // 对父组件传递过来的 action 进行数据监听
    action: {
      handler: function (newVal, oldVal) {
        this.action = newVal
      }
    }
  }
}
</script>

<style lang="less" scoped>
.add-user-from {
  width: 100%;
  height: 100%;
}
</style>