// 新增部门 dialog 对话框
<template>
  <div class="add-dept-from">
    <el-dialog :visible.sync="dialogVisibleDept" title="创建部门" :before-close="handleClose">
      <el-form ref="addDeptForm" :model="deptForm" label-width="100px" :rules="rules">
        <el-form-item label="上传部门" prop="parentId">
          <el-cascader v-model="deptForm.parentId" placeholder="请选择父级菜单" :options="deptList" :props="{checkStrictly:true,value:'_id',label:'deptName'}" clearable style="width:280px"></el-cascader>
          <span style="margin-left:20px;color:#909399">不选，则默认直接创建一级部门</span>
        </el-form-item>
        <el-form-item label="部门名称" prop="deptName">
          <el-input placeholder="请输入部门名称" v-model="deptForm.deptName"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="user">
          <span></span>
          <el-select v-model="deptForm.user" placeholder="请选择部门负责人" @change="handleUser">
            <el-option v-for="item in userList" :key="item.userId" :label="item.userName" :value="`${item.userId}/${item.userName}/${item.userEmail}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="负责人邮箱" prop="userEmail">
          <el-input disabled placeholder="请输入负责人邮箱" v-model="deptForm.userEmail"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel('addDeptForm')">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'Adddept',
  props: ['action', 'deptList', 'deptForm'],
  data() {
    return {
      dialogVisibleDept: false,
      // deptForm: {}, //表单对象
      userList: [], //用户列表
      rules: {
        parentId: [
          // {
          //   required: true,
          //   message: '请选择父级菜单',
          //   trigger: 'blur'
          // }
        ],
        deptName: [
          {
            required: true,
            message: '请输入部门名称',
            trigger: 'blur'
          }
        ],
        user: [
          {
            required: true,
            message: '请选择负责人',
            trigger: 'blur'
          }
        ]
      } //表单验证规则
    }
  },

  mounted() {
    this.getAllUserList()
  },

  methods: {
    //获取所有用户列表
    async getAllUserList() {
      const list = await this.$api.getAllUserList()
      this.userList = list
    },
    handleUser(val) {
      const [userId, userName, userEmail] = val.split('/')
      //进行浅拷贝 将以上数据拷贝到 deptForm 中
      Object.assign(this.deptForm, { userId, userName, userEmail })
    },
    //关闭弹窗提示
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
          this.$refs.addDeptForm.resetFields() //重置当前表单,避免选择按钮对后续的影响
          this.dialogVisibleDept = false
        })
        .catch(_ => {})
    },
    //表单控件关闭弹窗提示 -->取消按钮询问事件
    handleCancel(formName) {
      // console.log(this.action)
      //需要对 action 的状态进行判断 如果为 edit 则不清空表单
      if (this.action == 'add') {
        this.$confirm('确认取消？（取消后当前表单数据会丢失！）')
          .then(_ => {
            this.$refs[formName].resetFields() //重置当前表单
            this.dialogVisibleDept = false
          })
          .catch(_ => {})
      } else {
        this.$confirm('确认取消？')
          .then(_ => {
            // this.$refs[formName].resetFields() //重置当前表单
            this.dialogVisibleDept = false
          })
          .catch(_ => {})
      }
    },
    // 点击确定按钮提交，关闭弹窗
    handleSubmit() {
      this.$refs.addDeptForm.validate(async valid => {
        if (valid) {
          let params = this.deptForm
          params.action = this.action // 指定 action 添加/修改
          let res = await this.$api.deptOperate(params)
          // console.log(res)
          if (res) {
            this.dialogVisibleDept = false
            if (this.action == 'add') {
              this.$message.success('部门创建成功！(^～^)')
            } else {
              this.$message.success('部门修改成功！(^～^)')
            }
            this.$parent.getDeptList()
          }
        }
      })
    }
  },
  watch: {
    //使用深度监听，让数据进行响应式变化
    '$store.state.dialogVisibleDept': {
      immediate: true,
      deep: true,
      handler: function (newVal, oldVal) {
        this.dialogVisibleDept = newVal
      }
    },
    //使用数据监听，监听dialogVisibleDept数据变化，变化提交到vuex中
    dialogVisibleDept: {
      handler: function (newVal, oldVal) {
        this.$store.commit('changeDialogDept', this.dialogVisibleDept)
      }
    },
    //对父组件传递过来的 deptFrom 进行数据监听
    deptForm: {
      handler: function (newVal, oldVal) {
        this.deptForm = newVal
      }
    },
    // 对父组件传递过来的 action 进行数据监听
    action: {
      handler: function (newVal, oldVal) {
        this.action = newVal
      }
    },
    // 对父组件传递过来的 deptList 进行数据监听
    deptList: {
      handler: function (newVal, oldVal) {
        this.deptList = newVal
      }
    }
  }
}
</script>

<style lang="less" scoped>
.add-dept-from {
  width: 100%;
  height: 100%;
}
</style>