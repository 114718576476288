import { render, staticRenderFns } from "./Role.vue?vue&type=template&id=b8dd7a36&scoped=true&"
import script from "./Role.vue?vue&type=script&lang=js&"
export * from "./Role.vue?vue&type=script&lang=js&"
import style0 from "./Role.vue?vue&type=style&index=0&id=b8dd7a36&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8dd7a36",
  null
  
)

export default component.exports