<template>

  <dv-full-screen-container class="container">
    <div class="header-box">
      <Header @fatherMethod="exitFullscreen"></Header>
    </div>

    <!-- 内容主体 -->
    <div class="main-box1">
      <div class="main-body">
        <!-- 左侧装饰条 -->
        <div class="left-decorate"></div>
        <!-- 左侧按钮列表 -->
        <div class="left-btn">
          <div class="botton-item" @click="handleVideo(1)">
            <dv-border-box-2 :color="colorIndex == 1? ['#1af7ff','#1af7ff']:['#01c4f7','#01c4f7']">
              <div class="title">
                <span :class="colorIndex == 1 ? 'click-style':''">1号大棚监控</span>
              </div>
            </dv-border-box-2>
          </div>
          <div class="botton-item" @click="handleVideo(2)">
            <dv-border-box-2 :color="colorIndex == 2? ['#1af7ff','#1af7ff']:['#01c4f7','#01c4f7']">
              <div class="title">
                <span :class="colorIndex == 2 ? 'click-style':''">2号大棚监控</span>
              </div>
            </dv-border-box-2>
          </div>
          <div class="botton-item" @click="handleVideo(3)">
            <dv-border-box-2 :color="colorIndex == 3? ['#1af7ff','#1af7ff']:['#01c4f7','#01c4f7']">
              <div class="title">
                <span :class="colorIndex == 3 ? 'click-style':''">3号大棚监控</span>
              </div>
            </dv-border-box-2>
          </div>
          <div class="botton-item" @click="handleVideo(4)">
            <dv-border-box-2 :color="colorIndex == 4? ['#1af7ff','#1af7ff']:['#01c4f7','#01c4f7']">
              <div class="title">
                <span :class="colorIndex == 4 ? 'click-style':''">4号大棚监控</span>
              </div>
            </dv-border-box-2>
          </div>
          <div class="botton-item" @click="handleVideo(5)">
            <dv-border-box-2 :color="colorIndex == 5? ['#1af7ff','#1af7ff']:['#01c4f7','#01c4f7']">
              <div class="title">
                <span :class="colorIndex == 5 ? 'click-style':''">5号大棚监控</span>
              </div>
            </dv-border-box-2>
          </div>
        </div>
        <!-- 视频主体区域 -->
        <div class="mian-video">
          <div class="video-body">
            <dv-border-box-11 title="大棚监控视频" :color="['#1af7ff']">
              <div class="vs">
                <div id="vs">
                  <Xgplayer :config="config" @player="Player = $event" />
                </div>
              </div>
            </dv-border-box-11>
          </div>
          <div class="video-footer">
            <dv-border-box-13>
              <Radar ref="radar"></Radar>
            </dv-border-box-13>
          </div>
        </div>
        <!-- 右侧按钮列表 -->
        <div class="right-btn">
          <div class="botton-item" @click="handleVideo(6)">
            <dv-border-box-2 :color="colorIndex ==6? ['#1af7ff','#1af7ff']:['#01c4f7','#01c4f7']">
              <div class="title">
                <span :class="colorIndex == 6 ? 'click-style':''">6号大棚监控</span>
              </div>
            </dv-border-box-2>
          </div>
          <div class="botton-item" @click="handleVideo(7)">
            <dv-border-box-2 :color="colorIndex ==7? ['#1af7ff','#1af7ff']:['#01c4f7','#01c4f7']">
              <div class="title">
                <span :class="colorIndex == 7 ? 'click-style':''">7号大棚监控</span>
              </div>
            </dv-border-box-2>
          </div>
          <div class="botton-item" @click="handleVideo(8)">
            <dv-border-box-2 :color="colorIndex == 8? ['#1af7ff','#1af7ff']:['#01c4f7','#01c4f7']">
              <div class="title">
                <span :class="colorIndex == 8 ? 'click-style':''">8号大棚监控</span>
              </div>
            </dv-border-box-2>
          </div>
          <div class="botton-item" @click="handleVideo(9)">
            <dv-border-box-2 :color="colorIndex == 9? ['#1af7ff','#1af7ff']:['#01c4f7','#01c4f7']">
              <div class="title">
                <span :class="colorIndex == 9 ? 'click-style':''">9号大棚监控</span>
              </div>
            </dv-border-box-2>
          </div>
          <div class="botton-item" @click="handleVideo(10)">
            <dv-border-box-2 :color="colorIndex == 10? ['#1af7ff','#1af7ff']:['#01c4f7','#01c4f7']">
              <div class="title">
                <span :class="colorIndex == 10 ? 'click-style':''">10号大棚监控</span>
              </div>
            </dv-border-box-2>
          </div>
        </div>
        <!-- 右侧装饰条 -->
        <div class="right-decorate"></div>
      </div>
      <div class="main-footer">
        <!-- 底部左侧修饰条 -->
        <div class="footer-left-decoration">
          <dv-decoration-3 style="width:100%;height:50%;" />
          <dv-decoration-3 style="width:100%;height:50%;" />
        </div>
        <!-- 底部中间修饰条 -->
        <div class="footer-main-decoration">
          <dv-decoration-7 style="width:90%;height:30px;">
            <div class="foooter-decoration"></div>
          </dv-decoration-7>
        </div>
        <!-- 底部右侧修饰条 -->
        <div class="footer-right-decoration">
          <dv-decoration-3 style="width:100%;height:50%;" />
          <dv-decoration-3 style="width:100%;height:50%;" />
        </div>
      </div>
    </div>
  </dv-full-screen-container>
</template>

<script>
import Header from '@/components/HomePageComponents/Header.vue'
import Radar from '@/components/SinglePageComponents/RadarMap.vue'
import Xgplayer from 'xgplayer-vue'
export default {
  name: 'HomePage',
  components: { Header, Radar, Xgplayer },
  data() {
    return {
      colorIndex: 1,
      config: {
        id: 'vs',
        url: '//video.699pic.com/videos/67/91/35/b_x7qRj1r6EsNU1553679135.mp4',
        fluid: true,
        autoplay: true,
        ignores: ['replay', 'progress', 'time'],
        loop: true
      },
      Player: null
    }
  },

  mounted() {
    setTimeout(() => {
      this.$refs.radar.changeLegend(1)
    }, 1000)
  },

  methods: {
    // 视频切换
    handleVideo(id) {
      this.colorIndex = id
      this.$refs.radar.changeLegend(id)
      //切换视频播放地址
      switch (id) {
        case 1:
          this.Player.src = '//video.699pic.com/videos/67/91/35/b_x7qRj1r6EsNU1553679135.mp4'
          break
        case 2:
          this.Player.src = '//video.699pic.com/videos/33/31/84/a_AgY5r27rvWkA1595333184.mp4'
          break
        case 3:
          this.Player.src = '//video.699pic.com/videos/67/88/10/b_WhnEai1idn8T1553678810.mp4'
          break
        case 4:
          this.Player.src = '//video.699pic.com/videos/01/26/67/a_nt93Ikc3ZSwM1610012667.MP4'
          break
        case 5:
          this.Player.src = '//video.699pic.com/videos/49/94/02/a_uIY7j8YRPFuX1590499402.mp4'
          break
        case 6:
          this.Player.src = '//video.699pic.com/videos/76/11/88/b_YX7872vSeKbI1553761188.mp4'
          break
        case 7:
          this.Player.src = '//video.699pic.com/videos/89/23/36/a_WrMV01ZHTnGx1591892336.mp4'
          break
        case 8:
          this.Player.src = '//video.699pic.com/videos/01/36/28/a_XKY8MQu2rME21584013628.MP4'
          break
        case 9:
          this.Player.src = '//video.699pic.com/videos/81/96/01/a_ItWsA2R4FJM51588819601.mp4'
          break
        case 10:
          this.Player.src = '//video.699pic.com/videos/58/14/55/a_tBAJIQN8dGq71590581455.mp4'
          break
        default:
          this.Player.src = '//video.699pic.com/videos/67/91/35/b_x7qRj1r6EsNU1553679135.mp4'
      }
    },
    // 退出全屏
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
    }
  }
}
</script>

<style lang="less" scoped>
// 全屏样式的定义 使用最高权重
.fullscreen {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  z-index: 100;
  background-image: url('../assets/img/home-page-images/bg1.png');
  background-repeat: no-repeat;
  background-size: 100%;
}
body {
  margin: 0;
  .container {
    width: 100%;
    height: 100%;
    background: url('../assets/img/home-page-images/bg1.png');
    background-size: 100%;
    .header-box {
      width: 100%;
      height: 8%;
    }
    .main-box1 {
      width: 100%;
      height: 90%;
      .main-body {
        width: 100%;
        height: 92%;
        // border: 1px solid red;
        padding: 0 0.375rem 0 0.375rem;
        display: flex;
        // justify-content: space-between;
        .left-decorate,
        .right-decorate {
          position: relative;
          width: 2px;
          height: 98%;
          background-color: #1af7ff;
        }
        .left-decorate::before,
        .right-decorate::before {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          content: '';
          width: 16px;
          height: 16px;
          background-color: #1af7ff;
        }
        .left-decorate::after,
        .right-decorate::after {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          content: '';
          width: 16px;
          height: 16px;
          background-color: #1af7ff;
        }
        .left-btn,
        .right-btn {
          height: 100%;
          width: 25%;
          // border: 1px solid white;
        }
        .left-btn,
        .right-btn {
          display: flex;
          flex-wrap: wrap;
          align-content: stretch;
          .botton-item {
            margin-left: 0.375rem;
            margin-top: 50px;
            width: 80%;
            cursor: pointer;
          }
          .botton-item:nth-last-of-type(1) {
            margin-bottom: 50px;
          }
        }
        .title {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #01c4f7;
          font-size: 0.3rem;
          .click-style {
            color: #1af7ff;
          }
        }
        .right-btn {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          align-content: stretch;
          .botton-item {
            margin-right: 0.375rem;
            margin-top: 50px;
            width: 80%;
          }
          .botton-item:nth-last-of-type(1) {
            margin-bottom: 50px;
          }
        }
        .mian-video {
          height: 100%;
          width: 46.2%;
          // border: 1px solid red;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-content: space-between;
          .video-body {
            position: relative;
            padding: 10px;
            margin: 10px;
            width: 95%;
            height: 60%;
            font-weight: bold;
            // border: 1px solid white;
            .vs {
              // border: 1px solid white;
              padding-top: 45px;
              position: absolute;
              left: 50%;
              top: 57.5%;
              transform: translate(-50%, -50%);
              width: 95.5%;
              height: 100%;
              overflow: hidden;
            }
          }
          .video-footer {
            width: 95%;
            height: 35%;
            // border: 1px solid white;
          }
        }
      }
      .main-footer {
        width: 100%;
        height: 8%;
        // border: 1px solid white;
        display: flex;
        .footer-left-decoration,
        .footer-right-decoration {
          height: 100%;
          width: 16%;
          // border: 1px solid red;
          padding-bottom: 10px;
        }
        .footer-main-decoration {
          display: flex;
          width: 68%;
          height: 100%;
          // border: 1px solid white;
          justify-content: center;
          align-items: center;
          .foooter-decoration {
            position: relative;
            margin: 0 0.625rem 0 0.625rem;
            width: 90%;
            height: 2px;
            background-color: #1af7ff;
          }
          .foooter-decoration::before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            width: 16px;
            height: 16px;
            background-color: #1af7ff;
          }
          .foooter-decoration::after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            width: 16px;
            height: 16px;
            background-color: #1af7ff;
          }
        }
      }
    }
  }
}
</style>