<template>
  <div class="com-container">
    <!-- 各大棚数据 -->
    <div class="com-chart" ref="radar_ref">

    </div>
  </div>
</template>

<script>
export default {
  name: 'Radar',

  data() {
    return {
      chartInstane: null,
      allData: null, //从服务器获取数据
      currentIndex: 0,
      colorArr: ['#24B314', '#FB7636', '#027AD7', '#8452E7', '#9E87FF', '#FDFA4E', '#30ECA6', '#92E1FF', '#00FFFF', '#006CED'],
      selected: {
        '1号大棚': false,
        '2号大棚': false,
        '3号大棚': false,
        '4号大棚': false,
        '5号大棚': false,
        '6号大棚': false,
        '7号大棚': false,
        '8号大棚': false,
        '9号大棚': false,
        '10号大棚': false
      }
    }
  },

  mounted() {
    //初始化图表
    this.initChart()
    this.getData()
    //监听页面变化
    window.addEventListener('resize', this.screenAdapter)
    //第一次加载，主动调用
    this.screenAdapter()
  },
  destroyed() {
    //销毁监听事件
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    //初始化 echart 对象
    initChart() {
      this.chartInstane = this.$echarts.init(this.$refs.radar_ref, 'chalk')
      const initOption = {
        title: {
          text: '▎各大棚数据',
          left: '5%'
        },
        legend: {
          orient: 'vertical',
          top: '45%',
          left: '5%'
        },
        color: this.colorArr,
        tooltip: {},
        xAxis: {
          show: false
        },
        yAxis: {
          show: false
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            myTool1: {
              show: true,
              title: '全选',
              icon:
                'path://M511.1 957.94c-246.456 0-446.857-200.401-446.857-446.859S264.644 64.223 511.1 64.223c246.457 0 446.858 200.401 446.858 446.858S757.557 957.94 511.1 957.94z m0-851.396c-222.806 0-404.537 181.73-404.537 404.537S288.295 915.619 511.1 915.619c222.807 0 404.537-181.73 404.537-404.537S734.53 106.544 511.1 106.544z m167.417 262.017L447.619 601.325 343.685 496.767c-9.335-9.335-24.895-9.335-34.853 0-9.335 9.335-9.335 24.895 0 34.853l121.984 121.983c9.336 9.336 24.895 9.336 34.853 0 1.244-1.244 1.866-2.489 3.112-3.733l245.211-246.457c9.336-9.335 9.336-24.895 0-34.852-9.959-9.959-25.518-9.959-35.475 0z',
              onclick: () => {
                const allLegend = {
                  legend: {
                    selected: {
                      '1号大棚': true,
                      '2号大棚': true,
                      '3号大棚': true,
                      '4号大棚': true,
                      '5号大棚': true,
                      '6号大棚': true,
                      '7号大棚': true,
                      '8号大棚': true,
                      '9号大棚': true,
                      '10号大棚': true
                    }
                  }
                }
                this.chartInstane.setOption(allLegend)
              }
            },
            myTool2: {
              show: true,
              title: '清空',
              icon:
                'path://M901.3 504.8l-76.3-150c-13.4-26.3-40-42.6-69.5-42.6H639c-1.1 0-2-0.9-2-2V120.6c0-31.1-25.3-56.3-56.3-56.3h-90c-31.1 0-56.3 25.3-56.3 56.3v189.6c0 1.1-0.9 2-2 2H315.8c-29.5 0-56.1 16.3-69.5 42.6l-76.3 150c-9.2 18.1-8.4 39.3 2.2 56.6 10.3 16.8 27.9 27 47.4 27.6-4.8 101-38.3 205.9-90.2 279.5-12.5 17.8-14.1 40.8-4.1 60.1 10 19.3 29.7 31.3 51.5 31.3h601.5c35 0 66-23.6 75.2-57.4 15.5-56.5 28.4-107.9 29.4-164.9C884 685 874 636 852.9 589c19-1.1 36.1-11.2 46.2-27.6 10.6-17.3 11.4-38.5 2.2-56.6z m-681.4 25.4l76.3-150c3.8-7.4 11.3-12 19.6-12h116.4c32 0 58-26 58-58V120.6c0-0.1 0.2-0.3 0.3-0.3h90c0.1 0 0.3 0.2 0.3 0.3v189.6c0 32 26 58 58 58h116.4c8.3 0 15.8 4.6 19.6 12l76.3 150c0.2 0.3 0.5 1-0.1 2s-1.3 1-1.7 1H221.7c-0.4 0-1.1 0-1.7-1-0.6-1-0.3-1.7-0.1-2zM827 736.6c-0.9 50.5-12.9 98.3-27.4 151.1-2.6 9.5-11.3 16.2-21.2 16.2H651.8c11.3-22.3 18.5-44 23.1-61.2 7.1-26.7 10.7-53.5 10.6-78-0.1-17.1-15.5-30.1-32.4-27.4-13.6 2.2-23.6 14-23.6 27.8 0.1 42.7-14.1 98.2-42.7 138.8H406.2c15.2-21.7 26.1-43.8 33.6-61.9 10-24.3 17.4-49.7 21.2-72.5 2.8-17-10.4-32.5-27.6-32.5-13.6 0-25.3 9.8-27.6 23.3-2.8 16.6-8.3 37.7-17.7 60.4-10.1 24.6-27.8 58.1-55.6 83.3H176.9c-0.5 0-1.2 0-1.8-1.1-0.6-1.1-0.2-1.6 0.1-2 29.7-42.1 54.8-94.5 72.5-151.4 16.2-52.1 25.7-106.9 28-160.3h514.6C816 635.6 828 684 827 736.6z',
              onclick: () => {
                const restoreLegend = {
                  legend: {
                    selected: this.selected
                  }
                }
                this.chartInstane.setOption(restoreLegend)
              }
            },
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['pie', 'funnel']
            },
            // restore: {
            //   show: true
            // },
            // 重写还原工具

            saveAsImage: {
              show: true
            }
          }
        }
      }
      this.chartInstane.setOption(initOption)
    },
    //获取数据
    async getData() {
      const res = await this.$api.getSingleAll()
      this.allData = res
      //更新数据
      this.updateChart()
    },
    //更新处理数据
    updateChart() {
      const legendArr = this.allData.data.map(item => {
        return item.name
      })
      const dataArr = this.allData.data.map(item => {
        return {
          name: item.name,
          value: item.data
        }
      })
      const dataOption = {
        legend: {
          data: legendArr,
          selected: this.selected
        },
        radar: {
          indicator: this.allData.indicator
        },
        series: [
          {
            name: '各大棚数据',
            type: 'radar',
            data: dataArr
          }
        ]
      }
      this.chartInstane.setOption(dataOption)
    },
    //图表响应式变化
    screenAdapter() {
      const fontSize = (this.$refs.radar_ref.offsetWidth / 100) * 3.6
      const adapterOption = {
        legend: {
          itemWidth: fontSize / 2.5,
          itemHeight: fontSize / 2.5,
          itemGap: fontSize / 2.5,
          textStyle: {
            fontSize: fontSize / 2
          }
        }
      }
      this.chartInstane.setOption(adapterOption)
      this.chartInstane.resize()
    },
    //数据切换定时器
    startInterval() {
      if (this.timerId) {
        clearInterval(this.timerId)
      }
      this.timerId = setInterval(() => {
        this.currentIndex++
        if (this.currentIndex > 1) {
          this.currentIndex = 0
        }
        this.updateChart()
      }, 5000)
    },
    //图例切换
    changeLegend(id) {
      // 父组件传递id
      // 将对象转换为数组 [[],[]]
      const selectedArr = Object.entries(this.selected)
      //更改对应id的值
      selectedArr[id - 1][1] = true
      //转换为对象数组[{},{},{}]
      const arr = selectedArr.map(item => {
        return Object.assign({}, item)
      })
      //合并对象，转换为新对象 {key:value}
      const obj = {}
      arr.forEach(item => {
        obj[item[0]] = item[1]
      })
      //赋值
      // this.selected = obj
      const changeLegend = {
        legend: {
          selected: obj
        }
      }
      this.chartInstane.setOption(changeLegend)
    }
  }
}
</script>

<style lang="less" scoped>
.com-container {
  position: absolute;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 87%;
  overflow: hidden;

  .com-chart {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>