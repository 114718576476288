<template>
  <!-- 品种占有率 -- 饼图 -->
  <div class="com-container">
    <div class="com-chart" ref="share_ref">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Share',

  data() {
    return {
      chartInstane: null,
      allData: null, //从服务器获取数据
      totla: 0 //总数
    }
  },

  mounted() {
    // 初始化图表
    this.initChart()
    this.getData()
    //监听页面响应式变化
    window.addEventListener('resize', this.screenAdapter)
    //第一次加载，主动调用
    this.screenAdapter()
  },
  destroyed() {
    //销毁监听事件
    window.removeEventListener('resize', this.screenAdapter)
  },

  methods: {
    //初始化 echart 对象
    initChart() {
      this.chartInstane = this.$echarts.init(this.$refs.share_ref, 'chalk')
      const initOption = {
        legend: {
          top: '7.5%',
          icon: 'circle'
        },
        tooltip: {
          show: true,
          formatter: age => {
            return `${age.data.name}:${parseInt((age.data.value / this.total) * 100) + '%'}`
          }
        },
        series: [
          {
            type: 'pie',
            center: ['50%', '60%'],
            label: {
              show: false //默认隐藏图标
            },
            emphasis: {
              label: {
                show: true
              }
            }
          }
        ]
      }
      // 设置
      this.chartInstane.setOption(initOption)
    },
    //获取数据
    async getData() {
      const res = await this.$api.getShare()
      this.allData = res
      //更新数据
      this.updateChart()
    },
    //更新数据
    updateChart() {
      //处理图表所需要的数据
      const legendData = this.allData.children.map(item => {
        return item.name
      })
      var total = 0
      const seriesData = this.allData.children.map(item => {
        total = total + item.value
        return {
          name: item.name,
          value: item.value
        }
      })
      this.total = total
      const dataOption = {
        legend: {
          data: legendData
        },
        series: [{ type: 'pie', data: seriesData }]
      }
      this.chartInstane.setOption(dataOption)
    },
    // 图表相应式变化
    screenAdapter() {
      //计算文字大小
      const fontSize = (this.$refs.share_ref.offsetWidth / 100) * 3.6
      const adapterOption = {
        series: [
          // {   //半径大小
          //   radius: fontSize * 4.5
          // },
        ],
        //图例大小
        legend: {
          itemWidth: fontSize / 1.2,
          itemHeight: fontSize / 1.2,
          itemGap: fontSize / 1.2,
          textStyle: {
            fontSize: fontSize / 1.2
          }
        },
        label: {
          fontSize: fontSize / 1.2
        }
      }
      this.chartInstane.setOption(adapterOption)
      this.chartInstane.resize()
    }
  }
}
</script>

<style lang="less" scoped>
.com-container {
  position: absolute;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 87%;
  overflow: hidden;
  .com-chart {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>