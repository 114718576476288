<template>
  <div class="login-container">
    <el-card class="box-card" :body-style="{ padding: '0px' }">
      <div class="card-img">
        <h1>农业大棚后台管理系统</h1>
        <span>助力农业大棚数据可视化,优化内部管理</span>
      </div>
      <div class="card-form">
        <h3>欢迎登录</h3>
        <el-form ref="form" :model="form" class="modal" status-icon :rules="rules">
          <el-form-item prop="userName">
            <el-input type="text" prefix-icon="el-icon-user" placeholder="请输入用户名" v-model="form.userName"></el-input>
          </el-form-item>
          <el-form-item prop="userPwd">
            <el-input type="password" placeholder="请输入密码" prefix-icon="el-icon-lock" v-model="form.userPwd"></el-input>
          </el-form-item>
          <el-form-item prop="captcha">
            <div class="captcha-box">
              <el-input type="text" prefix-icon="el-icon-key" placeholder="请输入验证码" v-model="form.captcha"></el-input>
              <div class="svg-box">
                <div v-html="codeSVG" class="code-svg" @click="getCaptcha()"></div>
                <span class="code-tips">看不清？点击更换</span>
              </div>
            </div>
          </el-form-item>
          <div class="login-a">
            <el-checkbox v-model="checked">记住密码</el-checkbox>
            <a href="javascript:;" @click="retrievePassword()">忘记密码？</a>
          </div>
          <el-button type="primary" round size="medium" class="sub-btn" @click="login">登录</el-button>
        </el-form>
      </div>
    </el-card>
    <div class="login-footer">
      <span><a href="https://beian.miit.gov.cn/" target="_blank">皖ICP备2021016468号-1</a></span>
      <span>Copyright 2021 ZhouLong 版权所有 | 有问题请联系：2509777182@qq.com</span>
      <span>系统由 Vue+Node+ElementUI 驱动</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      form: {
        userName: '',
        userPwd: '',
        captcha: ''
      },
      rules: {
        userName: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          }
        ],
        userPwd: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }
        ],
        captcha: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ]
      },
      codeSVG: '',
      checked: true
    }
  },
  mounted() {
    this.$store.commit('saveUserInfo', {})
    // 获取验证码
    this.getCaptcha()
    //记住密码后自动填充
    const accountInfo = this.$storage.getItem('accountInfo')
    if (accountInfo) {
      this.form.userName = accountInfo.userName
      this.form.userPwd = accountInfo.userPwd
    }
  },
  methods: {
    //登录接口
    login() {
      this.$refs.form.validate(val => {
        //登录bug无法获取token
        this.$store.commit('saveUserInfo', this.form)
        if (val) {
          this.$api.login(this.form).then(res => {
            this.$store.commit('saveUserInfo', res)
            //记住密码 勾选则写入local storage 否则清空账号密码等信息
            if (this.checked) {
              this.$store.commit('saveAccountInfo', this.form)
            } else {
              this.$store.commit('saveAccountInfo', '')
            }
            // this.$router.push('/home')
            this.$router.push('/large')
          })
        } else {
          return false
        }
      })
    },
    //获取验证码
    getCaptcha() {
      //获取验证码
      const codeData = this.$api.loginCaptcha()
      console.log('req', codeData)
      codeData.then(res => {
        this.codeSVG = res
      })
    },
    //找回密码
    retrievePassword() {
      this.$message({
        message: '请联系企业管理员找回密码！',
        type: 'warning'
      })
    }
  }
}
</script>

<style lang="less" scoped>
//屏幕尺寸约束
@media screen and (max-width: 1024px) {
  html {
    font-size: 42px !important;
  }
}
@media screen and (min-width: 1920px) {
  html {
    font-size: 80px !important;
  }
}
.login-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  // min-width: 1024px;
  // max-width: 1920px;
  width: 100%;
  height: 100vh;
  background: url('../assets/img/admin-images/login-bg1.jpg') no-repeat top center;
  background-size: 100%;
  overflow: hidden;
  .box-card {
    float: left;
    width: 14.45rem;
    height: 7.5rem;
    border-radius: 0.125rem;
    box-sizing: border-box;
    .card-img {
      float: left;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      width: 8.95rem;
      height: 7.5rem;
      background: url('../assets/img/admin-images/login-bg.jpg') no-repeat top center;
      background-size: 100%;
      color: #ffffff;
      letter-spacing: 0.0625rem;
      h1 {
        padding-bottom: 0.3125rem;
        margin-bottom: 0;
        font-size: 0.5rem;
      }
      span {
        font-size: 0.3rem;
      }
    }
    .card-form {
      float: right;
      width: 5.399rem;
      height: 7.5rem;
      h3 {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        letter-spacing: 0.0625rem;
        text-align: center;
        color: #409eff;
        font-size: 0.3rem;
      }
      .modal {
        padding: 0 0.25rem;
        position: relative;
        .captcha-box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .el-input {
            width: 2.5rem;
          }
          .svg-box {
            position: relative;
            // border: 1px solid black;
            .code-svg {
              display: flex;
              height: 100%;
              width: 100%;
              // padding: 0.0625rem;
              border: 0.0125rem solid #dcdfe6;
              overflow: hidden;
              border-radius: 5px;
              vertical-align: top;
              box-sizing: border-box;
              svg {
                width: 100%;
                height: 100%;
              }
            }
            .code-tips {
              display: none;
              position: absolute;
              left: 10%;
              bottom: -30px;
              color: #f56c6c;
              font-size: 0.15rem;
            }
            .code-svg :hover {
              cursor: pointer;
            }
          }
          .svg-box:hover > .code-tips {
            display: block;
          }
        }

        .sub-btn {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          margin-top: 0.25rem;
          width: 80%;
        }
      }
    }
  }
  .login-a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      color: #409eff;
      text-decoration: underline;
    }
  }
  .login-footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    span {
      width: 100%;
      margin-bottom: 0.0625rem;
      color: #848586;
    }
    a {
      color: #52c41a;
    }
  }
}
</style>