// 新增角色 dialog 对话框
<template>
  <div class="add-role-from">
    <el-dialog :visible.sync="dialogVisibleRole" title="创建角色" :before-close="handleClose">
      <el-form ref="addRoleForm" :model="roleForm" label-width="100px" :rules="rules">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="roleForm.roleName" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" :rows="2" v-model="roleForm.remark" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel('addRoleForm')">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'Addrole',
  props: ['action', 'roleForm'],
  data() {
    return {
      dialogVisibleRole: false,
      // roleForm: {}, //表单对象
      rules: {
        roleName: [
          {
            required: true,
            message: '请输入菜单名称',
            trigger: 'blur'
          }
        ]
      } //表单验证规则
    }
  },

  mounted() {},

  methods: {
    //关闭弹窗提示
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
          this.$refs.addRoleForm.resetFields() //重置当前表单,避免选择按钮对后续的影响
          this.dialogVisibleRole = false
        })
        .catch(_ => {})
    },
    //表单控件关闭弹窗提示 -->取消按钮询问事件
    handleCancel(formName) {
      // console.log(this.action)
      //需要对 action 的状态进行判断 如果为 edit 则不清空表单
      if (this.action == 'add') {
        this.$confirm('确认取消？（取消后当前表单数据会丢失！）')
          .then(_ => {
            this.$refs[formName].resetFields() //重置当前表单
            this.dialogVisibleRole = false
          })
          .catch(_ => {})
      } else {
        this.$confirm('确认取消？')
          .then(_ => {
            // this.$refs[formName].resetFields() //重置当前表单
            this.dialogVisibleRole = false
          })
          .catch(_ => {})
      }
    },
    // 点击确定按钮提交，关闭弹窗
    handleSubmit() {
      this.$refs.addRoleForm.validate(async valid => {
        if (valid) {
          let params = this.roleForm
          params.action = this.action // 指定 action 添加/修改
          let res = await this.$api.roleOperate(params)
          // console.log(res)
          if (res) {
            this.dialogVisibleRole = false
            if (this.action == 'add') {
              this.$message.success('角色创建成功！(^～^)')
            } else {
              this.$message.success('角色编辑成功！(^～^)')
            }
            this.$parent.getRoleList()
          }
        }
      })
    }
  },
  watch: {
    //使用深度监听，让数据进行响应式变化
    '$store.state.dialogVisibleRole': {
      immediate: true,
      deep: true,
      handler: function (newVal, oldVal) {
        this.dialogVisibleRole = newVal
      }
    },
    //使用数据监听，监听dialogVisibleRole数据变化，变化提交到vuex中
    dialogVisibleRole: {
      handler: function (newVal, oldVal) {
        this.$store.commit('changeDialogRole', this.dialogVisibleRole)
      }
    },
    //对父组件传递过来的 roleFrom 进行数据监听
    roleForm: {
      handler: function (newVal, oldVal) {
        this.roleForm = newVal
      }
    },
    // 对父组件传递过来的 action 进行数据监听
    action: {
      handler: function (newVal, oldVal) {
        this.action = newVal
      }
    }
  }
}
</script>

<style lang="less" scoped>
.add-role-from {
  width: 100%;
  height: 100%;
}
</style>