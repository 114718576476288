<template>
  <!--测试页面 -->
  <div class="container">
    <el-container>
      <el-aside :width="isCollapse ? '64px': '200px'">
        <Aside></Aside>
      </el-aside>
      <el-container>
        <el-header>
          <Header></Header>
        </el-header>
        <el-main>
          <el-scrollbar style="height:100%;">
            <div class="main">
              <el-footer>Footer</el-footer>
            </div>
          </el-scrollbar>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Aside from '@/components/BackStagePageComponents/Aside.vue'
import Header from '@/components/BackStagePageComponents/Header.vue'
export default {
  name: 'NewPage',
  components: {
    Header,
    Aside
  },
  data() {
    return {
      isCollapse: false
    }
  },

  mounted() {},

  methods: {},
  watch: {
    '$store.state.isCollapse': {
      immediate: true,
      deep: true,
      handler: function (newVal, oldVal) {
        this.isCollapse = newVal
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
  .el-aside {
    height: 100vh;
    background-color: #f6ffed;
  }
  .el-header {
    height: 0.8rem;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-left: 1px solid #e8e8e8;
  }
  .el-main {
    max-height: 100vh;
    overflow-x: hidden;
    background-color: #f0f2f5;
    .main {
      position: relative;
      width: 100%;
      height: 1000px;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      .el-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.675rem;
        background-color: slategray;
      }
    }
  }
}
</style>