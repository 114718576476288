import Vue from 'vue'
import VueRouter from 'vue-router'
import SalePage from '@/views/SalePage.vue'
import SinglePage from '@/views/SinglePage.vue'
import Login from '@/views/Login.vue'
import HomePage from '@/views/HomePage.vue'
import BackStagePage from '@/views/BackStagePage.vue'
import Welcome from '@/components/BackStagePageComponents/Welcome.vue'
import User from '@/components/BackStagePageComponents/User.vue'
import Menu from '@/components/BackStagePageComponents/Menu.vue'
import Role from '@/components/BackStagePageComponents/Role.vue'
import Dept from '@/components/BackStagePageComponents/Dept.vue'
import Leave from '@/components/BackStagePageComponents/Leave.vue'
import Approve from '@/components/BackStagePageComponents/Approve.vue'
import AccountInfo from '@/components/BackStagePageComponents/AccountInfo.vue'
import NotFound from '@/views/404Page.vue'
import API from '@/api/index.js'
import storage from '../utils/storage'
import util from '@/utils/utils.js'
import Test from '@/views/Test.vue'
import TestPage from '@/views/TestPage.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  { path: '/', redirect: '/login' },
  { name: 'login', path: '/login', meta: { title: '登录' }, component: Login },
  { path: '/test', component: Test, meta: { title: '组件测试' } },
  { path: '/test/page', component: TestPage, meta: { title: '组件测试页' } },
  // { path: '/large', component: HomePage },
  { path: '/sale', meta: { title: '销售统计' }, component: SalePage },
  { path: '/single', meta: { title: '大棚监控' }, component: SinglePage },
  { name: 'notFound', path: '/404', meta: { title: '404 Not Found' }, component: NotFound },
  {
    //to-do 路径更改
    path: '/home', //系统管理
    name: 'home',
    component: BackStagePage,
    meta: { title: '首页' },
    redirect: '/home/welcome',
    children: [
      {
        name: 'welcome',
        path: '/home/welcome',
        meta: {
          title: '欢迎页'
        },
        component: Welcome
      },
      //首页大屏
      {
        name: '',
        path: '/large',
        meta: {
          title: '首页大屏'
        },
        component: HomePage
      },
      //系统管理
      {
        name: 'user',
        path: '/system/user',
        meta: {
          title: '用户管理'
        },
        component: User
      },
      {
        name: 'menu',
        path: '/system/menu',
        meta: {
          title: '菜单管理'
        },
        component: Menu
      },
      {
        name: 'role',
        path: '/system/role',
        meta: {
          title: '角色管理'
        },
        component: Role
      },
      {
        name: 'dept',
        path: '/system/dept',
        meta: {
          title: '部门管理'
        },
        component: Dept
      },
      //审批管理
      {
        name: 'leave',
        path: '/audit/leave',
        meta: {
          title: '休假申请'
        },
        component: Leave
      },
      //待审批
      {
        name: 'approve',
        path: '/audit/approve',
        meta: {
          title: '待审批'
        },
        component: Approve
      },
      //账户信息
      {
        name: 'account',
        path: '/account/info',
        meta: {
          title: '账户信息'
        },
        component: AccountInfo
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})
//判断当前地址是否可以访问
function checkPermission(path) {
  let hasPermission = router.getRoutes().filter(route => route.path === path).length
  if (hasPermission) {
    return true
  } else {
    return false
  }
}
//判断用户是否有访问权限--动态路由添加 bug 动态路由无法成功添加
async function loadAsyncRoutes() {
  let userInfo = storage.getItem('userInfo') || {}
  if (userInfo.token) {
    try {
      //根据权限获取菜单列表
      const { menuList } = await API.getPermissionList()
      let { routes } = util.generateRoute(menuList)
      // console.log(routes)
      // routes.map(route => {
      // let url = `./../components/BackStagePageComponents/${route.component}.vue`
      // route.component = () => import(url)
      // router.addRoute([
      //   {
      //     name: 'dept',
      //     path: '/system/dept',
      //     meta: {
      //       title: '部门管理'
      //     },
      //     component: Dept
      //   }
      // ])
      // })
    } catch (error) {}
  }
  console.log(router)
}
loadAsyncRoutes()
// 导航守卫
router.beforeEach((to, from, next) => {
  // 判断当前地址是否可以访问 判断是否有对应的 name
  if (checkPermission(to.path)) {
    document.title = to.meta.title
    next()
  } else {
    next('/404')
  }
})
export default router
