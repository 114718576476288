<template>
  <!-- 组件测试文件 -->
  <div class="com-page">
    <!-- <Temp></Temp> -->
    <!-- <Share></Share> -->
    <!-- <Map></Map> -->
    <!-- <Pre></Pre> -->
    <!-- <Plant></Plant> -->
    <!-- <Wind></Wind> -->
    <!-- <Yield></Yield> -->
    <!-- <Per></Per> -->
    <!-- <Hot></Hot> -->
    <!-- <Rank></Rank> -->
    <!-- <Analyze></Analyze> -->
    <Radar></Radar>
  </div>
</template>

<script>
import Temp from '@/components/HomePageComponents/Temperature.vue'
import Share from '@/components/HomePageComponents/Share.vue'
import Map from '@/components/HomePageComponents/ExperimentMap'
import Pre from '@/components/HomePageComponents/Precipitation.vue'
import Plant from '@/components/HomePageComponents/PlantList.vue'
import Wind from '@/components/HomePageComponents/WindSpeed.vue'
import Yield from '@/components/HomePageComponents/Yield.vue'
import Per from '@/components/SalePageComponents/PercentageComplete.vue'
import Hot from '@/components/SalePageComponents/Hot.vue'
import Rank from '@/components/SalePageComponents/RankingList.vue'
import Analyze from '@/components/SalePageComponents/Analyze.vue'
import Radar from '@/components/SinglePageComponents/RadarMap.vue'
export default {
  name: 'EchartAdminTest',
  components: {
    Temp,
    Share,
    Map,
    Pre,
    Plant,
    Wind,
    Yield,
    Per,
    Hot,
    Rank,
    Analyze,
    Radar
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="less" scoped>
.com-page {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  // background-color: '#000337';
  background-image: url('../assets/img/home-page-images/bg1.png');
  background-repeat: no-repeat;
  background-size: 100%;
}
</style>