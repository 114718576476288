<template>
  <div id="app">
    <router-view></router-view>

  </div>
</template>
<script>
export default {
  mounted() {}
}
</script>

<style lang="less">
a {
  text-decoration: none;
}
#id {
  width: 100%;
  height: 100%;
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}
</style>
