<template>
  <div class="header-container">
    <!-- 导航栏左边 -->
    <div class="nav-left">
      <!-- 菜单收藏展示按钮 -->
      <div class="menu-fold" @click="changeCollapse">
        <i :class="[isCollapse ? 'el-icon-s-unfold':'el-icon-s-fold']"></i>
      </div>
      <!-- 面包屑 -->
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-for="(item,index) in breadList" :key="item.path">
            <router-link to="/home/welcome" v-if="index==0">{{item.meta.title}}</router-link>
            <span v-else>{{item.meta.title}}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 用户信息 -->
    <div class="user-info">
      <!-- 消息提示 -->
      <el-badge :is-dot='noticeCount > 0 ? true:false' class="item">
        <i class="el-icon-bell" @click="handleNotice"></i>
      </el-badge>
      <!-- 下拉菜单 -->
      <el-dropdown class="dropdown" @command="handleCommand">
        <span class="el-dropdown-link">
          {{userInfo.userName||null}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command='email'>邮箱:{{userInfo.userEmail||null}}</el-dropdown-item>
          <el-dropdown-item command='logout'>退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',

  data() {
    return {
      isCollapse: false, //控制侧边栏显示隐藏
      userInfo: this.$store.state.userInfo //获取用户信息
      // noticeCount: 0 //消息提示小红点
    }
  },
  computed: {
    breadList() {
      return this.$route.matched
    },
    noticeCount() {
      return this.$store.state.noticeCount
    }
  },
  mounted() {
    this.getNoticeCount()
    // console.log(this.$route)
  },
  methods: {
    //侧边栏
    changeCollapse() {
      this.isCollapse = !this.isCollapse
      this.$store.commit('changeIsCollapse', this.isCollapse)
    },
    // 下拉菜单选择事件
    handleCommand(command) {
      if (command == 'email') return
      this.$store.commit('saveUserInfo', '')
      this.userInfo = null
      this.$router.push('/login')
    },
    //获取通知数量
    async getNoticeCount() {
      try {
        const count = await this.$api.noticeCount()
        this.$store.commit('saveNoticeCount', count)
      } catch (error) {
        console.error(error)
      }
    },
    //点击铃铛跳转
    handleNotice() {
      this.$router.push('/audit/approve')
    }
  }
}
</script>

<style lang="less" scoped>
.header-container {
  height: 100%;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .user-info {
    .item {
      // line-height: 30px;
      margin-right: 15px;
    }
    .item:hover {
      cursor: pointer;
    }
    .dropdown {
      margin-left: 20px;
    }
    .el-dropdown-link {
      cursor: pointer;
      color: #409eff;
    }
    .el-icon-arrow-down {
      font-size: 12px;
    }
  }
  .nav-left {
    display: flex;
    align-items: center;
    .breadcrumb {
      margin-left: 20px;
    }
  }
}
</style>