<template>
  <div class="header">
    <!-- 头部左侧 -->
    <div class="header-left">
      <router-link to="/large" class="home-btn" exact>
        <img v-if="$route.path === '/large' " src="@/assets/img/home-page-images/home-y.png" alt="">
        <img v-else src="@/assets/img/home-page-images/home-n.png" alt="">
        首页大屏
      </router-link>
      <span>/</span>
      <router-link to="/sale" class="sale-btn">
        <img v-if="$route.path === '/sale'" src="@/assets/img/home-page-images/sale-y.png" alt="">
        <img v-else src="@/assets/img/home-page-images/sale-n.png" alt="">
        销售统计
      </router-link>
      <span>/</span>
      <router-link to="/single" class="single-btn">
        <img v-if="$route.path === '/single'" src="@/assets/img/home-page-images/single-y.png" alt="">
        <img v-else src="@/assets/img/home-page-images/single-n.png" alt="">
        大棚监控
      </router-link>
    </div>
    <!-- 头部标题 -->
    <div class="header-title">农业大棚智能数字监控系统</div>
    <!-- 头部右侧 -->
    <div class="header-right">
      <div class="login-info"><span>{{userInfo.userName||null}}</span>欢迎使用!</div>
      <div class="time-info"><span>{{nowtime| updatetime('HH:mm')}}</span>{{nowtime| updatetime('YYYY-MM-DD')}}</div>
      <div class="login-out" @click="handleOut"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Header',
  data() {
    return {
      nowtime: new Date(), //获取当前时间
      userInfo: this.$store.state.userInfo //获取用户信息
    }
  },
  mounted() {
    let t = this
    this.timer = setInterval(() => {
      t.nowtime = new Date()
    }, 1000)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  filters: {
    updatetime(value, format) {
      return moment(value).format(format)
    }
  },
  methods: {
    handleOut() {
      this.$emit('fatherMethod')
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1.025rem;
  margin-top: 0.2125rem;
  background: url('../../assets/img/home-page-images/head-bg.png') no-repeat top center;
  background-size: 100%;
  color: white;
  overflow: hidden;
  // 头部左侧
  .header-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.3125rem;
    height: 70%;
    font-size: 0.225rem;
    span {
      color: #00ffff;
    }
    .home-btn,
    .sale-btn,
    .single-btn {
      img {
        width: 0.25rem;
        height: 0.25rem;
        vertical-align: middle;
        margin: 0 0.0625rem;
      }
      margin: 0 0.1875rem;
      color: #01c4f7;
    }
    .home-btn.router-link-active,
    .sale-btn.router-link-active,
    .single-btn.router-link-active {
      color: #00ffff;
    }
  }
  // 头部标题
  .header-title {
    flex: 1;
    text-align: center;
    font-size: 0.4rem;
    color: #01c4f7;
    letter-spacing: 4px;
  }
  // 头部右侧
  .header-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.3125rem;
    height: 70%;
    // color: #01c4f7;
    .login-info,
    .time-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 0.25rem;
      height: 0.625rem;
      font-size: 0.225rem;
      text-align: center;
      // border: 1px solid #ffffff;
      span {
        // display: inline-flexbox;
        font-size: 0.225rem;
        margin-bottom: 0.0625rem;
        text-align: center;
      }
    }
    .login-out {
      width: 1rem;
      height: 0.35rem;
      margin-left: 0.125rem;
      // border: 1px solid white;
      background-image: url('../../assets/img/home-page-images/loginout.png');
      background-size: 100%;
      cursor: pointer;
    }
  }
}
</style>