<template>
  <div class="aside-container">
    <div :class="[isCollapse ? 'aside-title':'unaside-title']"><img src="../../assets/img/admin-images/logo.jpg" alt="">
      <span v-if="!isCollapse">农业后台管理系统</span>
    </div>
    <el-menu background-color='#FFF' active-text-color='#1989FA' :class="['verticalClass',isCollapse ? 'fold':'unfold']" :collapse="isCollapse" router>
      <template v-for="menu in userMenuList">
        <el-submenu :key="menu._id" v-if="menu.children && menu.children.length>0 && menu.children[0].menuType == 1" :index="menu._id" :route="menu.path">
          <template #title>
            <i :class="menu.icon"></i>
            <span>{{menu.menuName}}</span>
          </template>
          <template v-for='mchild in menu.children'>
            <el-submenu :key="mchild._id" v-if="mchild.children && mchild.children.length>0 && mchild.children[0].menuType == 1" :index="mchild._id" :route="mchild.path">
              <template #title>
                <i :class="mchild.icon"></i>
                <span>{{mchild.menuName}}</span>
              </template>
            </el-submenu>
            <el-menu-item v-else-if='mchild.menuType == 1' :index="mchild._id" :key='mchild._id' :route="mchild.path">{{mchild.menuName}}</el-menu-item>
          </template>
        </el-submenu>
        <el-menu-item v-else-if='menu.menuType == 1' :index="menu._id" :key='menu._id' :route="menu.path">{{menu.menuName}}</el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from '@/utils/eventBus.js'
export default {
  name: 'Aside',
  data() {
    return {
      isCollapse: false,
      userMenuList: [], //侧边栏菜单数据
      changeMenuList: [] //to-do 传入的数据中，一旦数据经过查询操作，侧边栏也会对应改变|现通过判断数据是否变化，让侧边栏自己刷新数据
    }
  },
  computed: {
    actionIndex() {
      return this.$route.path
    }
  },
  created() {
    // 数据通过 eventBus 由 Menu.vue 获取后传入该组件
    bus.$on('getMenuList', val => {
      this.changeMenuList = val //一旦该数据发生变化，则调用this.getMenuList()，侧边栏自行获取方法|方法冗余-->to-do
    })
  },
  mounted() {
    this.getMenuList()
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    //获取菜单数据
    async getMenuList() {
      try {
        const { menuList, actionList } = await this.$api.getPermissionList()
        this.userMenuList = menuList
        this.$store.commit('saveUserAction', actionList)
      } catch (error) {
        console.error(error)
      }
    }
  },
  watch: {
    '$store.state.isCollapse': {
      immediate: true,
      deep: true,
      handler: function (newVal, oldVal) {
        this.isCollapse = newVal
      }
    },
    changeMenuList: {
      immediate: true,
      deep: true,
      handler: function (newVal, oldVal) {
        this.getMenuList()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.aside-container {
  height: 100%;
  .unaside-title {
    width: 100%;
    height: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.2375rem;
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
    color: #409eff;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.4);
    img {
      width: 0.375rem;
      height: 0.375rem;
      vertical-align: middle;
      margin-right: 0.0625rem;
    }
  }
  .aside-title {
    position: relative;
    width: 100%;
    height: 0.75rem;
    // visibility: hidden;
    img {
      width: 0.475rem;
      height: 0.475rem;
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      // vertical-align: middle;
    }
  }
}
// 解决侧边栏收起卡顿问题
.horizontal-collapse-transition {
  transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
}
.is-active {
  color: #409eff;
  background-color: #d1e7fe !important;
  border-right: 3px solid #409eff;
  border-radius: 25px 0 0 25px;
}
.el-menu {
  border: 0;
}
.fold {
  // margin-top: 60px;
  width: 64px;
}
.unfold {
  width: 200px;
}
</style>