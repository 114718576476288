// api 统一封装管理
import request from '@/utils/request.js'
export default {
  //登录验证码
  loginCaptcha(params) {
    return request({
      url: '/captcha',
      method: 'get',
      data: params
    })
  },
  // 获取登录数据
  login(params) {
    return request({
      url: '/login',
      method: 'post',
      data: params
    })
  },
  //获取通知数量
  noticeCount(params) {
    return request({
      url: '/leave/count',
      method: 'get',
      data: params
    })
  },
  //获取侧边栏菜单数据
  meunList(params) {
    return request({
      url: '/menu/list',
      method: 'get',
      data: params
    })
  },
  //根据权限获取侧边栏数据
  getPermissionList(params) {
    return request({
      url: '/users/getPermissionList',
      method: 'get',
      data: params
    })
  },
  // 获取用户列表
  userList(params) {
    return request({
      url: '/users/list',
      method: 'get',
      data: params
    })
  },
  //获取所有用户列表
  getAllUserList(params) {
    return request({
      url: '/users/all/list',
      method: 'get',
      data: params
    })
  },
  //删除用户：批量删除/单个删除
  userDel(params) {
    return request({
      url: '/users/delete',
      method: 'post',
      data: params
    })
  },
  // 新增用户：系统角色 角色名称列表获取
  rolesAllList(params) {
    return request({
      url: '/roles/allList',
      method: 'get',
      data: params
    })
  },
  // 新增用户，编辑用户接口
  operateUsers(params) {
    return request({
      url: '/users/operate',
      method: 'post',
      data: params
    })
  },
  //新增菜单提交
  menuSubmit(params) {
    return request({
      url: '/menu/operate',
      method: 'post',
      data: params
    })
  },
  //角色管理列表
  getRoleList(params) {
    return request({
      url: '/roles/list',
      method: 'get',
      data: params
    })
  },
  //新增角色
  roleOperate(params) {
    return request({
      url: '/roles/operate',
      method: 'post',
      data: params
    })
  },
  // 角色权限设置
  updatePermission(params) {
    return request({
      url: '/roles/update/permission',
      method: 'post',
      data: params
    })
  },
  // 部门管理：部门列表获取
  deptList(params) {
    return request({
      url: '/dept/list',
      method: 'get',
      data: params
    })
  },
  //部门管理：新增/编辑/删除
  deptOperate(params) {
    return request({
      url: '/dept/operate',
      method: 'post',
      data: params
    })
  },
  // 请假申请列表
  getApplyList(params) {
    return request({
      url: '/leave/list',
      method: 'get',
      data: params
    })
  },
  //请假申请
  leaveOperate(params) {
    return request({
      url: '/leave/operate',
      method: 'post',
      data: params
    })
  },
  //休假审核
  leaveApprove(params) {
    return request({
      url: '/leave/approve',
      method: 'post',
      data: params
    })
  },
  // 24小时温度
  getTemperature(params) {
    return request({
      url: '/visual/temperature',
      method: 'get',
      data: params
    })
  },
  // 各组件占比
  getShare(params) {
    return request({
      url: '/visual/share',
      method: 'get',
      data: params
    })
  },
  //首页试验田地图获取
  getUserMap(params) {
    return request({
      url: '/visual/map',
      method: 'get',
      data: params
    })
  },
  //首页一个月降水数据
  getRainfall(params) {
    return request({
      url: '/visual/rainfall',
      method: 'get',
      data: params
    })
  },
  //首页种植列表数据
  getPlantList(params) {
    return request({
      url: '/visual/plant',
      method: 'get',
      data: params
    })
  },
  //首页24小时风速
  getWindSpeed(params) {
    return request({
      url: '/visual/wind',
      method: 'get',
      data: params
    })
  },
  //首页各大棚产量
  getYield(params) {
    return request({
      url: '/visual/yield',
      method: 'get',
      data: params
    })
  },
  //首页大棚气象数据 -->暂定接口
  getWeather(params) {
    return request({
      url: '/visual/collect',
      method: 'get',
      data: params
    })
  },
  //销售完成情况
  getPercentage(params) {
    return request({
      url: '/market/percentage',
      method: 'get',
      data: params
    })
  },
  //热销农作物占比
  getHotData(params) {
    return request({
      url: '/market/hot',
      method: 'get',
      data: params
    })
  },
  //地区销售排行
  getRankingList(params) {
    return request({
      url: '/market/rank',
      method: 'get',
      data: params
    })
  },
  //销售金额分析
  getAnalyze(params) {
    return request({
      url: '/market/analyze',
      method: 'get',
      data: params
    })
  },
  //所有大棚数据
  getSingleAll(params) {
    return request({
      url: '/single/all',
      method: 'get',
      data: params
    })
  }
}
