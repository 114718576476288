import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import request from '@/utils/request.js'
import storage from '@/utils/storage.js'
import api from '@/api/index.js'
import { fullScreenContainer, borderBox11, borderBox12, borderBox13, borderBox2, digitalFlop, scrollBoard, borderBox6, decoration3, decoration7 } from '@jiaminghi/data-view'

Vue.config.productionTip = false
//全局挂载 request
Vue.prototype.$request = request
//全局挂载 storage
Vue.prototype.$storage = storage
//全局挂载 api
Vue.prototype.$api = api
//将全局echarts对象挂载到vue原型对象上  可以通过this.$echarts 调用
Vue.prototype.$echarts = window.echarts
//使用 ElementUI
Vue.use(ElementUI) //, { size: 'small', zIndex: 3000 }
//dataV组件使用
Vue.use(fullScreenContainer)
  .use(borderBox12)
  .use(digitalFlop)
  .use(scrollBoard)
  .use(borderBox6)
  .use(decoration3)
  .use(decoration7)
  .use(borderBox2)
  .use(borderBox13)
  .use(borderBox11)
//定义全局指令
Vue.directive('has', {
  bind(el, binding, vnode) {
    //获取按钮权限
    let actionList = storage.getItem('userAction')
    let value = binding.value
    //判断列表中是否有对应按钮的权限标识，没有则删除该节点
    let hasPermission = actionList.includes(value)
    if (!hasPermission) {
      el.style = 'display:none'
      //虚拟节点，未挂载道dom上，延迟执行
      setTimeout(() => {
        el.parentNode.removeChild(el)
      })
    }
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
