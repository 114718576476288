// 申请休假 dialog 对话框 
<template>
  <div class="add-user-from">
    <el-dialog :visible.sync="dialogVisibleLeave" title="休假申请" :before-close="handleClose">
      <el-form ref="addLeaveForm" :model="leaveForm" label-width="100px" :rules="rules">
        <el-form-item label="休假类型 " prop="applyType" required>
          <el-select v-model="leaveForm.applyType">
            <el-option label="事假" :value="1"></el-option>
            <el-option label="调休" :value="2"></el-option>
            <el-option label="年假" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="起始日期" required>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="startTime">
                <el-date-picker v-model="leaveForm.startTime" type="date" @change="(value)=>handleDateChange('startTime',value)" placeholder="请选择开始日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="1">-</el-col>
            <el-col :span="8">
              <el-form-item prop="endTime">
                <el-date-picker v-model="leaveForm.endTime" type="date" @change="(value)=>handleDateChange('endTime',value)" placeholder="请选择结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="休假时长">
          {{leaveForm.leaveTime}}
        </el-form-item>
        <el-form-item label="休假原因" prop="reasons">
          <el-input type="textarea" :row="4" placeholder="请输入休假原因" v-model="leaveForm.reasons"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel('addLeaveForm')">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'AddLeave',
  props: ['action', 'leaveForm'],
  data() {
    return {
      dialogVisibleLeave: false,
      rules: {
        applyType: [
          {
            required: true,
            message: '请选择休假类型',
            trigger: 'blur'
          }
        ],
        startTime: [
          {
            type: 'date',
            required: true,
            message: '请输入开始日期',
            trigger: 'blur'
          }
        ],
        endTime: [
          {
            type: 'date',
            required: true,
            message: '请输入结束日期',
            trigger: 'blur'
          }
        ],
        reasons: [
          {
            required: true,
            message: '请输入休假原因',
            trigger: 'blur'
          }
        ]
      } //表单验证规则
    }
  },

  mounted() {},

  methods: {
    //关闭弹窗提示
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
          this.dialogVisibleLeave = false
        })
        .catch(_ => {})
    },
    //表单控件关闭弹窗提示 -->取消按钮询问事件
    handleCancel(formName) {
      //需要对 action 的状态进行判断 如果为 edit 则不清空表单
      if (this.action == 'add') {
        this.$confirm('确认取消？（取消后当前表单数据会丢失！）')
          .then(_ => {
            this.$refs[formName].resetFields() //重置当前表单
            this.dialogVisibleLeave = false
          })
          .catch(_ => {})
      } else {
        this.$confirm('确认取消？')
          .then(_ => {
            this.dialogVisibleLeave = false
          })
          .catch(_ => {})
      }
    },
    // 点击确定按钮提交，关闭弹窗
    handleSubmit() {
      this.$refs.addLeaveForm.validate(async valid => {
        if (valid) {
          let params = this.leaveForm
          params.action = this.action // 指定 action 添加/修改
          let res = await this.$api.leaveOperate(params)
          if (res) {
            this.dialogVisibleLeave = false
            if (this.action == 'add') {
              this.$message.success('申请休假成功！(^～^)')
            } else {
              this.$message.success('用户修改成功！(^～^)')
            }
            this.$parent.getApplyList()
          }
        }
      })
    },
    //计算选择日期时长
    handleDateChange(key, value) {
      let { startTime, endTime } = this.leaveForm
      //判断两日期是否为空
      if (!(startTime && endTime)) return
      //判断起始日期关系
      if (startTime > endTime) {
        this.$message.error('开始日期不能晚于结束日期')
        leaveForm.leaveTime = '0天'
        setTimeout(() => {
          this.leaveForm[key] = ''
        }, 0)
      } else {
        this.leaveForm.leaveTime = (endTime - startTime) / (24 * 60 * 60 * 1000) + 1 + '天'
      }
    }
  },
  watch: {
    '$store.state.dialogVisibleLeave': {
      immediate: true,
      deep: true,
      handler: function (newVal, oldVal) {
        this.dialogVisibleLeave = newVal
      }
    },
    //使用数据监听，监听dialogVisibleLeave数据变化，变化提交到vuex中
    dialogVisibleLeave: {
      handler: function (newVal, oldVal) {
        this.$store.commit('changeDialogLeave', this.dialogVisibleLeave)
      }
    },
    // 对父组件传递过来的 action 进行数据监听
    action: {
      handler: function (newVal, oldVal) {
        this.action = newVal
      }
    }
  }
}
</script>

<style lang="less" scoped>
.add-user-from {
  width: 100%;
  height: 100%;
}
</style>