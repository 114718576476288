<template>
  <div class="welcome-container container">
  </div>
</template>

<script>
export default {
  name: 'Welcome',

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="less" scoped>
.welcome-container {
  width: 100%;
  height: 90vh;
  background: url('../../assets/img/admin-images/welcome.jpg') center no-repeat;
  background-size: 100%;
}
</style>