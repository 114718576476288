<template>
  <!-- 试验田分布地图 -->
  <div class="com-container" @dblclick="revertMap">
    <div class="com-chart" ref="experiment_ref"></div>
  </div>
</template>

<script>
import { getProvinceMapInfo } from '@/utils/map_utils.js'
// 引入 axios 获取本地 json文件资源
import axios from 'axios'
export default {
  name: 'Experimentmap',

  data() {
    return {
      chartInstane: null,
      allData: null, //从服务器获取的数据
      mapData: {} //省份地图矢量数据缓存
    }
  },

  mounted() {
    //初始化图表
    this.initChart()
    this.getData()
    //监听页面变化
    window.addEventListener('resize', this.screenAdapter)
    //第一次加载，主动调用
    this.screenAdapter()
  },
  destroyed() {
    //销毁监听事件
    window.removeEventListener('resize', this.screenAdapter)
  },
  methods: {
    //初始化 echart 对象
    async initChart() {
      this.chartInstane = this.$echarts.init(this.$refs.experiment_ref, 'chalk')
      // const mapData = await axios.get('http://localhost:8080/static/map/china.json')
      const mapData = await axios.get('../../static/map/china.json') //线上环境
      this.$echarts.registerMap('china', mapData.data)
      //配置项
      const initOption = {
        title: {
          text: '全国试验田分布',
          textAlign: 'auto',
          left: 'center'
        },
        geo: {
          type: 'map',
          map: 'china',
          top: '15%',
          bottom: '5%',
          itemStyle: {
            areaColor: '#2E72BF',
            borderColor: '#333'
          }
        },
        legend: {
          left: '5%',
          bottom: '8%',
          orient: 'vertical'
        }
      }
      //点击事件监听
      this.chartInstane.on('click', async arg => {
        const provinceInfo = getProvinceMapInfo(arg.name)
        //如果对应名称无对应 json 数据 不执行该请求
        if (provinceInfo.key !== undefined) {
          // 缓存是否有改数据
          if (!this.mapData[provinceInfo.key]) {
            // const ret = await axios.get('http://localhost:8080' + provinceInfo.path)
            console.log(provinceInfo.path)
            const ret = await axios.get('../..' + provinceInfo.path) //线上环境
            // 写入缓存
            this.mapData[provinceInfo.key] = ret.data
            this.$echarts.registerMap(provinceInfo.key, ret.data)
          }
          const changeOption = {
            geo: {
              map: provinceInfo.key
            }
          }
          this.chartInstane.setOption(changeOption)
        }
      })
      //设置
      this.chartInstane.setOption(initOption)
    },
    // 获取数据
    async getData() {
      const res = await this.$api.getUserMap()
      this.allData = res
      //更新数据
      this.updateChart()
    },
    //更新处理数据
    updateChart() {
      //数据配置
      const seriesArr = this.allData.map(item => {
        return {
          type: 'effectScatter',
          name: item.name,
          data: item.children,
          coordinateSystem: 'geo', //根据坐标渲染散点
          rippleEffect: {
            //设置散点涟漪效果
            scale: 5,
            brushType: 'stroke'
          }
        }
      })
      //图例数据
      const legendArr = this.allData.map(item => {
        return item.name
      })
      const dataOption = {
        legend: {
          data: legendArr
        },
        series: seriesArr
      }
      this.chartInstane.setOption(dataOption)
    },
    //图表响应式变化
    screenAdapter() {
      //计算文字大小
      const fontSize = (this.$refs.experiment_ref.offsetWidth / 100) * 3.6
      const adapterOption = {
        title: {
          textStyle: {
            fontSize: fontSize
          }
        },
        legend: {
          itemWidth: fontSize / 2,
          itemHeight: fontSize / 2,
          itemGap: fontSize / 2,
          textStyle: {
            fontSize: fontSize / 2
          }
        }
      }
      this.chartInstane.setOption(adapterOption)
      this.chartInstane.resize()
    },
    //双击，回到中国地图
    revertMap() {
      const revertOption = {
        geo: {
          map: 'china'
        }
      }
      this.chartInstane.setOption(revertOption)
    }
  }
}
</script>

<style lang="less" scoped>
.com-container {
  position: absolute;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 87%;
  overflow: hidden;

  .com-chart {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>