// 新增菜单 dialog 对话框
<template>
  <div class="add-menu-from">
    <el-dialog :visible.sync="dialogVisibleMenu" title="创建菜单" :before-close="handleClose">
      <el-form ref="addMenuForm" :model="menuForm" label-width="100px" :rules="rules">
        <el-form-item label="父级菜单" prop="parentId">
          <el-cascader v-model="menuForm.parentId" placeholder="请选择父级菜单" :options="menuList" :props="{checkStrictly:true,value:'_id',label:'menuName'}" clearable style="width:280px"></el-cascader>
          <span style="margin-left:20px;color:#909399">不选，则默认直接创建一级菜单</span>
        </el-form-item>
        <el-form-item label="菜单类型" prop="menuType">
          <el-radio-group v-model="menuForm.menuType">
            <el-radio :label="1">菜单</el-radio>
            <el-radio :label="2">按钮</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="menuForm.menuType == 1 ? '菜单名称':'按钮名称'" prop="menuName">
          <el-input v-model="menuForm.menuName" :placeholder="menuForm.menuType == 1 ? '请输入菜单名称':'请输入按钮名称'"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标" prop="icon" v-show="menuForm.menuType == 1">
          <el-input v-model="menuForm.icon" placeholder="请输入菜单图标"></el-input>
        </el-form-item>
        <el-form-item label="路由地址" prop="path" v-show="menuForm.menuType == 1">
          <el-input v-model="menuForm.path" placeholder="请输入路由地址"></el-input>
        </el-form-item>
        <el-form-item label="权限标识" prop="menuCode">
          <el-input v-model="menuForm.menuCode" placeholder="请输入权限标识"></el-input>
        </el-form-item>
        <el-form-item label="组件路径" prop="component" v-show="menuForm.menuType == 1">
          <el-input v-model="menuForm.component" placeholder="请输入组件路径"></el-input>
        </el-form-item>
        <el-form-item label="菜单状态" prop="menuState" v-show="menuForm.menuType == 1">
          <el-radio-group v-model="menuForm.menuState">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="2">停用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel('addMenuForm')">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'AddMenu',
  props: ['action', 'menuForm', 'menuList'],
  data() {
    return {
      dialogVisibleMenu: false,
      // menuForm: {
      //   // state: 3
      // }, //表单对象
      rules: {
        menuName: [
          {
            required: true,
            message: '请输入菜单名称',
            trigger: 'blur'
          },
          {
            min: 2,
            max: 10,
            message: '长度在2-8个字符',
            trigger: 'blur'
          }
        ]
      } //表单验证规则
    }
  },

  mounted() {},

  methods: {
    //关闭弹窗提示
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
          this.$refs.addMenuForm.resetFields() //重置当前表单,避免选择按钮对后续的影响
          this.dialogVisibleMenu = false
        })
        .catch(_ => {})
    },
    //表单控件关闭弹窗提示 -->取消按钮询问事件
    handleCancel(formName) {
      // console.log(this.action)
      //需要对 action 的状态进行判断 如果为 edit 则不清空表单
      if (this.action == 'add') {
        this.$confirm('确认取消？（取消后当前表单数据会丢失！）')
          .then(_ => {
            this.$refs[formName].resetFields() //重置当前表单
            this.dialogVisibleMenu = false
          })
          .catch(_ => {})
      } else {
        this.$confirm('确认取消？')
          .then(_ => {
            // this.$refs[formName].resetFields() //重置当前表单
            this.dialogVisibleMenu = false
          })
          .catch(_ => {})
      }
    },
    // 点击确定按钮提交，关闭弹窗
    handleSubmit() {
      this.$refs.addMenuForm.validate(async valid => {
        if (valid) {
          let params = this.menuForm
          params.action = this.action // 指定 action 添加/修改
          let res = await this.$api.menuSubmit(params)
          // console.log(res)
          if (res) {
            this.dialogVisibleMenu = false
            if (this.action == 'add') {
              this.$message.success('菜单创建成功！(^～^)')
            } else {
              this.$message.success('菜单修改成功！(^～^)')
            }
            this.$parent.getMenuList()
          }
        }
      })
    }
  },
  watch: {
    //使用深度监听，让数据进行响应式变化
    '$store.state.dialogVisibleMenu': {
      immediate: true,
      deep: true,
      handler: function (newVal, oldVal) {
        this.dialogVisibleMenu = newVal
      }
    },
    //使用数据监听，监听dialogVisibleMenu数据变化，变化提交到vuex中
    dialogVisibleMenu: {
      handler: function (newVal, oldVal) {
        this.$store.commit('changeDialogMenu', this.dialogVisibleMenu)
      }
    },
    //对父组件传递过来的 menuFrom 进行数据监听
    menuForm: {
      handler: function (newVal, oldVal) {
        this.menuForm = newVal
      }
    },
    // 对父组件传递过来的 action 进行数据监听
    action: {
      handler: function (newVal, oldVal) {
        this.action = newVal
      }
    },
    // 对父组件传递过来的 menuList 进行数据监听
    menuList: {
      handler: function (newVal, oldVal) {
        this.menuList = newVal
      }
    }
  }
}
</script>

<style lang="less" scoped>
.add-menu-from {
  width: 100%;
  height: 100%;
}
</style>