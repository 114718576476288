<template>
  <div class="container use-container">
    <div class="query-from">
      <el-form :inline="true" :model="queryForm" class="demo-form-inline" ref="form">
        <el-form-item label="部门名称" prop="deptName">
          <el-input v-model="queryForm.deptName" placeholder="请输入部门名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
          <el-button @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="base-table">
      <div class="action">
        <el-button type="primary" @click="handleAdd">创建</el-button>
      </div>
      <div class="pagintation">
        <el-table :data='deptList' row-key="_id" :tree-props="{children:'children',}" max-height="800">
          </el-table-column>
          <el-table-column v-for="item in columns" :key="item.prop" :prop="item.prop" :label="item.label" :width="item.width" :formatter="item.formatter" :sortable="item.sortable">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleEdit(scope.row)" type="primary">编辑</el-button>
              <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 弹窗提交表单封装为组件 -->
    <add-dept :deptList='deptList' :action='action' :deptForm="deptForm"></add-dept>
  </div>
</template>

<script>
import moment from 'moment'
import bus from '@/utils/eventBus.js'
import AddDept from '@/components/BackStagePageComponents/AddDept.vue'
export default {
  name: 'Dept',
  components: {
    AddDept
  },
  data() {
    return {
      queryForm: {},
      columns: [
        {
          label: '部门名称',
          prop: 'deptName'
        },
        {
          label: '负责人',
          prop: 'userName'
        },
        {
          label: '创建时间',
          prop: 'createTime',
          sortable: true,
          formatter(row, column, value) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss')
          }
        },
        {
          label: '更新时间',
          prop: 'updateTime',
          sortable: true,
          formatter(row, column, value) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss')
          }
        }
      ],
      deptList: [],
      dialogVisibleDept: false, // 是否打开新增用户dialog对话框
      deptForm: {}, //传给子组件的表单信息
      action: '' //传给子组件状态 add:添加 | edit:修改
    }
  },

  mounted() {
    this.getDeptList()
  },

  methods: {
    //getDeptList
    async getDeptList() {
      try {
        let list = await this.$api.deptList(this.queryForm)
        this.deptList = list
      } catch (error) {
        throw new Error(error)
      }
    },
    //查询菜单
    handleQuery() {
      this.getDeptList()
    },
    //重置表单
    handleReset() {
      this.$refs.form.resetFields()
    },
    //删除菜单
    async handleDelete(row) {
      let _id = row._id
      let res = await this.$api.deptOperate({ _id, action: 'delete' })
      this.$message.success('删除成功！')
      this.getDeptList()
    },
    //编辑菜单
    handleEdit(row) {
      this.action = 'edit'
      this.dialogVisibleDept = true
      this.$store.commit('changeDialogDept', this.dialogVisibleDept)
      this.$nextTick(() => {
        //子组件重置按钮是指初始化打开时的状态 ， $nextTick()是等页面渲染完毕后再添加数据
        this.deptForm = row
        // this.deptForm.user = `${row.userId}/${row.userName}/${row.userEmail}`
        this.deptForm.user = row.userName
        // Object.assign(this.deptForm, row, {
        //   user: `${row.userId}/${row.userName}/${row.userEmail}`
        // })
      })
    },
    //新增菜单
    handleAdd() {
      this.deptForm = {
        parentId: [null] //默认为空值
      }
      this.dialogVisibleDept = true
      this.action = 'add'
      this.$store.commit('changeDialogDept', this.dialogVisibleDept)
    }
  },
  watch: {
    deptList: {
      //监听 deptList 数据变化，并将数据传入到 Aside.vue 中
      immediate: true,
      deep: true,
      handler: function (newVal, oldVal) {
        bus.$emit('getDeptList', newVal)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.use-container {
  height: 100%;
  background-color: #f0f2f5;
  .query-from {
    // width: 100%;
    // height: 50px;
    padding: 22px 20px 0;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #ebebeb;
  }
  .base-table {
    border-radius: 5px;
    background-color: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #ebebeb;
    .action {
      border-radius: 5px 5px 0px 0px;
      background-color: #ffffff;
      padding: 20px;
      border-bottom: 1px solid #ece8e8;
    }
    .pagintation {
      text-align: right;
      padding: 10px;
    }
  }
}
</style>