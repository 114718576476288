// 对 Storage 存储进行二次封装
//创建一个 manager 命名空间 便于管理
const namespace = 'manager'
export default {
  getStroage() {
    return JSON.parse(window.localStorage.getItem(namespace) || '{}')
  },
  setItem(key, val) {
    let storage = this.getStroage() //storage = {key ,{...}}
    //key为变量(调用时传入)，storage.key为字符串
    storage[key] = val
    // console.log('调用了setItem')
    window.localStorage.setItem(namespace, JSON.stringify(storage)) //转换为 JSON 字符串
  },
  getItem(key) {
    return this.getStroage()[key]
  },
  clearItem(key) {
    let storage = this.getStroage()
    delete storage[key]
    //删除，让这个为空后 需要重新进行写入
    window.localStorage.setItem(namespace, JSON.stringify(storage))
  },
  clearAll() {
    window.localStorage.clear()
  }
}
