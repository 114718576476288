<template>

  <dv-full-screen-container class="containerHome">
    <div class="header-box">
      <Header @fatherMethod="exitFullscreen"></Header>
    </div>
    <!-- 内容主体 -->
    <div class="main-box1">

      <!-- 左侧盒子 -->
      <div class="main-left">
        <!-- 气象数据总汇 -->
        <dv-border-box-12 class="weather-total">
          <div class="title">气象数据汇总</div>
          <weather-total></weather-total>
        </dv-border-box-12>
        <!--温度变化  -->
        <dv-border-box-12 class="temperature-variation ">
          <!-- 全屏点击按钮 -->
          <div class="icon-full-screen" @click="changeFullScreen('temp')"></div>
          <div class="title">
            24小时温湿度变化
          </div>
          <div :class="[fullScreenStatus.temp ? 'fullscreen':'']">
            <div v-if="fullScreenStatus.temp" class="icon-screen" @click="changeFullScreen('temp')"></div>
            <div v-if="fullScreenStatus.temp" class="title-screen">24小时温湿度变化</div>
            <Temp ref="temp"></Temp>
          </div>
        </dv-border-box-12>
        <!-- 品种占有率 -->
        <dv-border-box-12 class="share">
          <!-- 全屏点击按钮 -->
          <div class="icon-full-screen" @click="changeFullScreen('share')"></div>
          <div class="title">
            各品种占有率
          </div>
          <div :class="[fullScreenStatus.share ? 'fullscreen':'']">
            <div v-if="fullScreenStatus.share" class="icon-screen" @click="changeFullScreen('share')"></div>
            <div v-if="fullScreenStatus.share" class="title-screen">各品种占有率</div>
            <Share ref="share"></Share>
          </div>
        </dv-border-box-12>
      </div>

      <!-- 中间盒子 -->
      <div class="main-content">
        <!-- 首页地图模块 -->
        <dv-border-box-12 class="home-map">
          <!-- 全屏点击按钮 -->
          <div class="icon-full-screen" @click="changeFullScreen('map')"></div>
          <div :class="[fullScreenStatus.map ? 'fullscreen':'']">
            <div v-if="fullScreenStatus.map" class="icon-screen" @click="changeFullScreen('map')"></div>
            <Map ref="map"></Map>
          </div>
        </dv-border-box-12>
        <dv-border-box-12 class="data-info">
          <div class="icon-full-screen" @click="changeFullScreen('pre')"></div>
          <div class="title">
            月降水量变化
          </div>
          <div :class="[fullScreenStatus.pre ? 'fullscreen':'']">
            <div v-if="fullScreenStatus.pre" class="icon-screen" @click="changeFullScreen('pre')"></div>
            <div v-if="fullScreenStatus.pre" class="title-screen">月降水量变化</div>
            <Pre ref="pre"></Pre>
          </div>
        </dv-border-box-12>
      </div>

      <!-- 右侧盒子 -->
      <div class="main-right">
        <!-- 种植记录列表 -->
        <dv-border-box-12 class="plant-list">
          <div class="title">种植记录列表</div>
          <Plant></Plant>
        </dv-border-box-12>
        <!-- 24小时风速变化 -->
        <dv-border-box-12 class="precipitation-change">
          <div class="icon-full-screen" @click="changeFullScreen('wind')"></div>
          <div class="title">
            24小时风速变化
          </div>
          <div :class="[fullScreenStatus.wind ? 'fullscreen':'']">
            <div v-if="fullScreenStatus.wind" class="icon-screen" @click="changeFullScreen('wind')"></div>
            <div v-if="fullScreenStatus.wind" class="title-screen">24小时温湿度变化</div>
            <Wind ref="wind"></Wind>
          </div>
        </dv-border-box-12>
        <!-- 各大棚产量占比 -->
        <dv-border-box-12 class="output">
          <div class="icon-full-screen" @click="changeFullScreen('yield')"></div>
          <div class="title">
            各大棚产量占比
          </div>
          <div :class="[fullScreenStatus.yield ? 'fullscreen':'']">
            <div v-if="fullScreenStatus.yield" class="icon-screen" @click="changeFullScreen('yield')"></div>
            <div v-if="fullScreenStatus.yield" class="title-screen">各大棚产量占比</div>
            <Yield ref="yield"></Yield>
          </div>
        </dv-border-box-12>
      </div>
    </div>
  </dv-full-screen-container>
</template>

<script>
import Header from '@/components/HomePageComponents/Header.vue'
import WeatherTotal from '@/components/HomePageComponents/WeatherTotal.vue'
import Temp from '@/components/HomePageComponents/Temperature.vue'
import Share from '@/components/HomePageComponents/Share.vue'
import Map from '@/components/HomePageComponents/ExperimentMap.vue'
import Pre from '@/components/HomePageComponents/Precipitation.vue'
import Plant from '@/components/HomePageComponents/PlantList.vue'
import Wind from '@/components/HomePageComponents/WindSpeed.vue'
import Yield from '@/components/HomePageComponents/Yield.vue'
export default {
  name: 'HomePage',
  components: { Header, WeatherTotal, Temp, Share, Map, Pre, Plant, Wind, Yield },
  data() {
    return {
      // 全屏状态数据
      fullScreenStatus: {
        temp: false,
        share: false,
        map: false,
        pre: false,
        wind: false,
        yield: false
      }
    }
  },

  created() {
    //进入全屏
    this.$nextTick(() => {
      this.launchIntoFullscreen(document.documentElement)
    })
    //全屏事件监听
    // document.addEventListener('fullscreenchange', this.fullscreenchangeFunc, false)
  },

  methods: {
    changeFullScreen(name) {
      this.fullScreenStatus[name] = !this.fullScreenStatus[name]
      this.$nextTick(() => {
        this.$refs[name].screenAdapter()
      })
    },
    //进入全屏
    launchIntoFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      }
    },
    // 退出全屏
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
    },
    //全屏事件监听--退出全屏,进入后台非全屏页面
    fullscreenchangeFunc() {
      let isFull = document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || false
      if (!isFull) {
        this.$router.push({ path: '/home' })
      }
    },
    destroy() {
      //移除事件监听
      // document.removeEventListener('fullscreenchange', this.fullscreenchangeFunc)
    }
  }
}
</script>

<style lang="less" scoped>
// 全屏样式的定义 使用最高权重
.fullscreen {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  z-index: 100;
  background-image: url('../assets/img/home-page-images/bg1.png');
  background-repeat: no-repeat;
  background-size: 100%;
}
body {
  margin: 0;
  .containerHome {
    width: 100%;
    height: 100%;
    background: url('../assets/img/home-page-images/bg1.png');
    background-size: 100%;
    .header-box {
      width: 100%;
      height: 8%;
    }
    .main-box1 {
      width: 100%;
      height: 89%;
      // padding-bottom: 100px;
      // border: 1px solid #fff;
      display: flex;
      justify-content: space-between;
      .main-left,
      .main-right,
      .main-content {
        flex: 1;
        // border: 1px solid #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .weather-total,
        .temperature-variation,
        .share {
          flex: 1;
          .echart-box {
            width: 100%;
            height: 100%;
          }
          // overflow: hidden;
          // padding-top: 10px;
        }
      }
      .main-content {
        flex: 2;
        .home-map {
          height: 70%;
        }
        .data-info {
          height: 30%;
        }
      }
      .icon-full-screen {
        position: absolute;
        top: 0.225rem;
        right: 0.225rem;
        width: 0.375rem;
        height: 0.375rem;
        background: url('../assets/img/home-page-images/full-screen.png') no-repeat;
        background-size: 100%;
        overflow: hidden;
        cursor: pointer;
        z-index: 99;
      }
      .icon-screen {
        position: absolute;
        top: 40px;
        right: 40px;
        width: 40px;
        height: 40px;
        background: url('../assets/img/home-page-images/cancel-full-screen.png') no-repeat;
        background-size: 100%;
        overflow: hidden;
        cursor: pointer;
      }
      .title-screen {
        position: absolute;
        top: 0.25rem;
        left: 50%;
        transform: translateX(-50%);
        color: #ffffff;
        font-size: 0.6rem;
      }
      .title {
        position: absolute;
        top: -0.18rem;
        left: 50%;
        transform: translateX(-50%);
        width: 2.7625rem;
        height: 0.4875rem;
        background-image: url('../assets/img/home-page-images/title-bg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        color: #ffff;
        line-height: 0.4875rem;
        text-align: center;
        font-size: 0.225rem;
      }
      // 公共样式
      .weather-total,
      .plant-list,
      // .home-map,
      .temperature-variation,
      .precipitation-change,
      .share,
      .output,
      .data-info {
        margin-top: 15px;
        position: relative;
        background-repeat: no-repeat;
        background-size: 100%;
        // border: 1px solid white;
      }
    }
  }
}
</style>