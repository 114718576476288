import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import mutations from './mutations'
import storage from '@/utils/storage.js'
const state = {
  userInfo: '' || storage.getItem('userInfo'), //获取用户信息,
  accountInfo:{},//账号密码等信息
  isCollapse: false, //侧边栏状态
  dialogVisibleUser: false, //新增用户弹窗状态
  dialogVisibleMenu: false, //新增菜单
  dialogVisibleRole: false, //新增角色
  dialogVisibleSetRole: false, //权限管理
  dialogVisibleDept: false, //部门管理
  actionList: storage.getItem('actionList') || [], //权限按钮列表
  dialogVisibleLeave: false, //申请休假
  noticeCount: 0 //通知数量
}
export default new Vuex.Store({
  state,
  mutations
})
